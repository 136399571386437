import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { AuthService } from '../../../services/index';

@Component({
  selector: 'magic-link-dialog',
  templateUrl: './magic-link-dialog.component.html',
  styleUrls: ['./magic-link-dialog.component.css'],
})
export class MagicLinkDialogComponent implements OnInit {
  error = false;
  saving = false;
  loading = false;

  form!: FormGroup;
  emailFormControl: FormControl = new FormControl();

  constructor(
    public matDialogRef: MatDialogRef<MagicLinkDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private constants: Constants,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.emailFormControl = new FormControl(
      { value: this.data.email ?? '', disabled: false },
      [Validators.required, Validators.email]
    );

    this.form = this.fb.group({
      email: this.emailFormControl,
    });
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }

    this.process();
  }

  process(): void {
    const data = {
      email: this.emailFormControl.value,
    };

    this.loading = true;
    this.authService
      .createMagicLink(data)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.matDialogRef.close({ email: this.emailFormControl.value });
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }
}
