import {
  Component,
  ViewChild,
  EventEmitter,
  Output,
  OnInit,
  AfterViewInit,
  Input,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TwoOptionAlertComponent } from './two-option-alert/two-option-alert.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
declare const google: any;

@Component({
  selector: 'AutocompleteComponent',
  template: `
    <div class="form-field-label" style="margin-bottom: 0px">
      Start typing address...<span style="color: red">*</span>
    </div>
    <link-text
      (onTapped)="showInfoDialog()"
      text="Can't find your establishment?"
    ></link-text>

    <input
      class="form-input"
      #addresstext
      [(ngModel)]="autocompleteInput"
      type="text"
    />
  `,
})
export class AutocompleteComponent implements OnInit {
  @Input() adressType: string = '';
  @Input() country = 'GB';
  @Input() locationName = '';
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  autocompleteInput: string = '';
  queryWait: boolean = false;
  autocomplete: any;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  showInfoDialog(): void {
    this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: "Can't find your establishment?",
        body: this.getBody,
        buttonTwo: 'OK',
      },
      width: '450px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  get getBody() {
    return `The address lookup primarily focuses on establishments rather than precise physical addresses. For instance, if your location is named <b>${this.locationNameToInsert}</b> at <b>123 Park Road, London</b>, try entering <b>${this.locationNameToInsert}</b> if it doesn't appear in the suggested addresses.
    <br><br>
    If you're still unable to find the correct address for your establishment, feel free to select any other address so you're able to proceed and we'll promptly review and correct it for you before your location goes live.
    <br><br>
    Please don't hesitate to contact us via email or the in-browser chat if you need any corrections to be made.
  `;
  }

  get locationNameToInsert(): string {
    return this.locationName === ''
      ? "Jane's Delicious Gelato"
      : this.locationName;
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  ngOnChanges() {
    if (this.autocomplete) {
      this.autocomplete.setComponentRestrictions({ country: this.country });
    } else {
      this.getPlaceAutocomplete();
    }
  }

  private getPlaceAutocomplete() {
    if (!this.autocomplete && this.addresstext) {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.addresstext?.nativeElement,
        {
          componentRestrictions: { country: this.country },
          types: [this.adressType], // 'establishment' / 'address' / 'geocode'
        }
      );

      google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
        const place = this.autocomplete.getPlace();
        this.invokeEvent(place);
      });
    }
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }
}
