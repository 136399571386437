import { File } from './index';

export interface Merchant {
  _id?: string;
  nanoid?: string;
  name?: string;
  type?: string;
  admins?: string[];
  logo?: File;
  active?: boolean;
  hasNFC?: boolean;
  dismissedNotices?: string[];
  reports?: {
    day: boolean;
    week: boolean;
    month: boolean;
  };
  conversations?: {
    active?: boolean;
    emailNotifications?: boolean;
    canBeMessaged?: boolean;
    info?: string;
  };
  onboard?: {
    location: boolean;
    reward: boolean;
    card: boolean;
  };
  customBranding?: boolean;
}
