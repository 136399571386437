import { Component, Inject, NgZone, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';

@Component({
  selector: 'single-select-dialog',
  templateUrl: './single-select-dialog.component.html',
  styleUrls: ['./single-select-dialog.component.css'],
})
export class SingleSelectDialogComponent implements OnInit {
  form!: FormGroup;

  formControl!: FormControl;

  options: any[] = [];

  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder,
    public matDialogRef: MatDialogRef<SingleSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public zone: NgZone
  ) {
    if (this.data.number) {
      for (var i = 0; i < this.data.number; i++) {
        this.options.push({ number: i + 1 });
      }
    } else {
      this.options = this.data.options;
    }
  }

  ngOnInit() {
    let initialValue;

    if (this.data.number) {
      initialValue = this.options[0];
    }

    if (this.data.initialValue) {
      initialValue = this.data.initialValue;
    }

    this.formControl = new FormControl(
      {
        value: initialValue,
        disabled: false,
      },
      Validators.required
    );

    this.form = this.fb.group({
      number: this.formControl,
    });
  }

  changed(): void {}

  primaryTapped(): void {
    if (this.form.invalid) {
      return;
    }

    this.save();
  }

  save(): void {
    this.matDialogRef.close(this.formControl.value);
  }
}
