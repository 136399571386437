<div>
  <page-header
    [title]="constants.strings.engage"
    [subtitle]="subtitle"
  ></page-header>

  <div
    style="display: flex; justify-content: flex-end; margin-bottom: 10px"
    *ngIf="selectedTab !== tabs[2]"
  >
    <app-primary-button
      style="margin-right: 10px"
      [title]="constants.strings.automations"
      [disabled]="false"
      (click)="openAutomations()"
      [icon]="'bolt'"
    ></app-primary-button>

    <app-primary-button
      [title]="constants.strings.newMessage"
      [disabled]="false"
      [mat-menu-trigger-for]="menu"
      [icon]="'add'"
    ></app-primary-button>
  </div>

  <div
    style="display: flex; justify-content: flex-end; margin-bottom: 10px"
    *ngIf="selectedTab == tabs[2]"
  >
    <app-primary-button
      [title]="constants.strings.settings"
      [disabled]="false"
      (click)="openSettings()"
      [icon]="'settings'"
    ></app-primary-button>
  </div>

  <div class="detail-container">
    <mat-tab-group
      disableRipple="true"
      [animationDuration]="0"
      (selectedTabChange)="tabChanged($event.index)"
      [(selectedIndex)]="selectedIndex"
    >
      <mat-tab label="Messages">
        <ngx-datatable
          *ngIf="!messagesLoading"
          #myTable
          class="material"
          [headerHeight]="45"
          [rows]="messages"
          [scrollbarV]="false"
          [scrollbarH]="true"
          [footerHeight]="50"
          [columnMode]="'force'"
          [rowHeight]="60"
          [externalPaging]="true"
          [messages]="{
            emptyMessage:
              '<a href=https://app.remyrewards.co.uk/engage-push>+ Create your first Push Notification</a>'
          }"
          [count]="messageLength"
          [offset]="messagePageIndex"
          (page)="messagePageEvent($event)"
          (activate)="rowTappedNGX($event, false)"
          (scroll)="handleScroll()"
          [limit]="pageSize"
        >
          <ngx-datatable-column name="Date" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row?.createdAt | date : constants.longDate }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="Message"
            [width]="250"
            [resizeable]="false"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row?.message ?? "" | truncate : 40 }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="# Recipients" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ (row?.recipients ?? 0) === 0 ? "-" : row?.recipients }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Click Rate %" [resizeable]="false">
            <ng-template ngx-datatable-header-template>
              Click Rate
              <span>
                <info-icon
                  text="Clicks are registered only when a customer taps on the push notification, if they simply dismiss it will not count as a click, clicks do not necessarily equate to reads"
                ></info-icon>
              </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ clickRate(row) }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Reward" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <mat-icon
                class="status-icon"
                style="color: green"
                *ngIf="row?.reward"
                >check_circle</mat-icon
              >
              <mat-icon
                class="status-icon"
                style="color: red"
                *ngIf="!row?.reward"
                >cancel</mat-icon
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="Status"
            [resizeable]="false"
            [minWidth]="200"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div
                id="status-chip"
                [ngClass]="
                  row?.draft ? 'status-chip-unredeemed' : 'status-chip-redeemed'
                "
                [innerHTML]="status(row)"
              ></div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Actions" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <mat-icon
                [mat-menu-trigger-for]="rowMenu"
                (click)="$event.stopPropagation()"
                [matMenuTriggerData]="{ row: row }"
                >more_horiz</mat-icon
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-offset="offset"
              let-isVisible="isVisible"
            >
              <div class="page-count" *ngIf="messageLength > 0">
                <span>{{ messagesRange }}</span>
              </div>

              <datatable-pager
                [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'"
                [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'"
                [page]="messagePageIndex + 1"
                [size]="pageSize"
                [count]="messageLength"
                [hidden]="!(rowCount / pageSize > 1)"
                (change)="messageTable.onFooterPage($event)"
              >
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </mat-tab>

      <mat-tab label="Birthday Club" *ngIf="hasBirthdayClubAccess">
        <ngx-datatable
          *ngIf="!birthdayLoading"
          #myTable
          class="material"
          [headerHeight]="45"
          [rows]="birthdayNotifications"
          [scrollbarV]="false"
          [scrollbarH]="true"
          [footerHeight]="50"
          [columnMode]="'force'"
          [rowHeight]="48"
          [externalPaging]="true"
          [messages]="{
            emptyMessage: 'Sent Birthday Club messages will show here'
          }"
          [count]="birthdayLength"
          [offset]="birthdayPageIndex"
          (page)="birthdayPageEvent($event)"
          (activate)="rowTappedNGX($event, true)"
          (scroll)="handleScroll()"
          [limit]="pageSize"
        >
          <ngx-datatable-column name="Date" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row?.createdAt | date : constants.longDate }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="Message"
            [width]="250"
            [resizeable]="false"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row?.message | truncate : 40 }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Customer" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <a
                [routerLink]="['/customer', row?.customer?.nanoid]"
                (click)="$event.stopPropagation()"
              >
                {{ row?.customer?.user?.name }}
              </a>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Reward" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row?.data?.reward?.title || "-" }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Clicked" [resizeable]="false">
            <ng-template ngx-datatable-header-template>
              Clicked
              <span>
                <info-icon
                  text="Clicks are registered only when a customer taps on the push notification, if they simply dismiss it will not count as a click, clicks do not necessarily equate to reads"
                ></info-icon>
              </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <mat-icon
                class="status-icon"
                style="color: green"
                *ngIf="row?.delivered || row?.seen"
                >check_circle</mat-icon
              >
              <mat-icon
                class="status-icon"
                style="color: red"
                *ngIf="!row?.delivered && !row?.seen"
                >cancel</mat-icon
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Reward Status" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div
                *ngIf="row?.data?.reward?.title"
                id="status-chip"
                [ngClass]="constants.statusChipClass(row?.userReward)"
              >
                {{ constants.statusChipLabel(row?.userReward) }}
              </div>
              <div *ngIf="!row?.data?.reward?.title">-</div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-offset="offset"
              let-isVisible="isVisible"
            >
              <div class="page-count" *ngIf="birthdayLength > 0">
                <span>{{ birthdayRange }}</span>
              </div>

              <datatable-pager
                [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'"
                [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'"
                [page]="birthdayPageIndex + 1"
                [size]="pageSize"
                [count]="birthdayLength"
                [hidden]="!(rowCount / pageSize > 1)"
                (change)="birthdayTable.onFooterPage($event)"
              >
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </mat-tab>

      <mat-tab label="Conversations" *ngIf="showConversationsTab">
        <ngx-datatable
          *ngIf="!conversationsLoading"
          #myTable
          class="material"
          [headerHeight]="45"
          [rows]="conversations"
          [scrollbarV]="false"
          [scrollbarH]="true"
          [footerHeight]="50"
          [columnMode]="'force'"
          [rowHeight]="60"
          [externalPaging]="true"
          [messages]="{
            emptyMessage: 'No conversations'
          }"
          [count]="conversationLength"
          [offset]="conversationPageIndex"
          (page)="messagePageEvent($event)"
          (activate)="rowTappedNGX($event, false)"
          (scroll)="handleScroll()"
          [limit]="pageSize"
        >
          <ngx-datatable-column name="Last Message" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row?.lastMessage?.createdAt | date : constants.longDate }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Customer" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row?.user?.name }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Message" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row?.lastMessage?.text ?? "" | truncate : 40 }}
              <span class="new-chip" *ngIf="!seen(row)">NEW</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="Status"
            [resizeable]="false"
            [minWidth]="200"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div
                id="status-chip"
                [ngClass]="
                  conversationStatus(row) === 'Blocked'
                    ? 'status-chip-expired'
                    : conversationStatus(row) === 'Muted'
                    ? 'status-chip-unredeemed'
                    : 'status-chip-redeemed'
                "
              >
                {{ conversationStatus(row) }}
                <span *ngIf="showInfoIcon(row)"
                  ><info-icon [text]="infoIconText(row)"></info-icon
                ></span>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Actions" [resizeable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <mat-icon
                [mat-menu-trigger-for]="conversationMenu"
                (click)="$event.stopPropagation()"
                [matMenuTriggerData]="{ row: row }"
                >more_horiz</mat-icon
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-offset="offset"
              let-isVisible="isVisible"
            >
              <div class="page-count" *ngIf="conversationLength > 0">
                <span>{{ conversationsRange }}</span>
              </div>

              <datatable-pager
                [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'"
                [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'"
                [page]="conversationPageIndex + 1"
                [size]="pageSize"
                [count]="conversationLength"
                [hidden]="!(rowCount / pageSize > 1)"
                (change)="messageTable.onFooterPage($event)"
              >
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<loader [loading]="loading"></loader>

<mat-menu #menu="matMenu" class="custom-menu">
  <div *ngFor="let item of menuOptions; let i = index">
    <button
      [disabled]="false"
      [style.cursor]="'pointer'"
      class="menu-list-item"
      style="border-radius: 5px"
      (click)="onClick(item.title, null)"
      mat-menu-item
    >
      <mat-icon
        fontSet="material-icons-outlined"
        style="
          font-size: 16px;
          vertical-align: middle;
          display: flex;
          align-items: center;
          margin-right: 5px;
        "
        >{{ item.icon }}</mat-icon
      >
      {{ item.title }}
    </button>
  </div>
</mat-menu>

<mat-menu #rowMenu="matMenu" class="custom-menu">
  <ng-template matMenuContent let-row="row">
    <div *ngFor="let item of row | rowMenuOptions : constants">
      <button
        [disabled]="false"
        class="menu-list-item"
        style="border-radius: 5px; cursor: pointer"
        (click)="onClick(item.title, row)"
        mat-menu-item
      >
        <mat-icon
          fontSet="material-icons-outlined"
          style="
            font-size: 16px;
            vertical-align: middle;
            display: flex;
            align-items: center;
            margin-right: 5px;
          "
        >
          {{ item.icon }}
        </mat-icon>
        {{ item.title }}
      </button>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #conversationMenu="matMenu" class="custom-menu">
  <ng-template matMenuContent let-row="row">
    <div *ngFor="let item of row | conversationMenuOptions : constants">
      <button
        [disabled]="false"
        class="menu-list-item"
        style="border-radius: 5px; cursor: pointer"
        (click)="onClick(item.title, row)"
        mat-menu-item
      >
        <mat-icon
          fontSet="material-icons-outlined"
          style="
            font-size: 16px;
            vertical-align: middle;
            display: flex;
            align-items: center;
            margin-right: 5px;
          "
        >
          {{ item.icon }}
        </mat-icon>
        {{ item.title }}
      </button>
    </div>
  </ng-template>
</mat-menu>
