import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, Input, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { TwoOptionAlertComponent } from 'src/app/shared/components/two-option-alert/two-option-alert.component';
import { RewardDialogComponent } from '../../card/reward-dialog/reward-dialog.component';
import { RecipientsFilterDialog } from '../recipients-filter-dialog/recipients-filter-dialog.component';
import { EmojiDialogComponent } from '../emoji-dialog/emoji-dialog.component';
import { AIDialogComponent } from '../ai-dialog/ai-dialog.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import {
  Message,
  SendAs,
  User,
  Customer,
  File,
  Location,
} from '../../../models/index';
import {
  MerchantService,
  AuthService,
  LocationService,
} from '../../../services/index';

@Component({
  selector: 'compose-pn',
  templateUrl: './compose-pn.component.html',
  styleUrls: ['./compose-pn.component.css'],
})
export class ComposePnComponent {
  @Input() smallTitle = false;
  @ViewChild('myTable') table: any;

  sendOption = 0;
  title = '';
  subtitle: string = '';
  pageIndex = 0;
  pageSize = 1000;
  length = 0;
  submitted = false;
  originalCustomers: Customer[] = [];
  customers: Customer[] = [];
  rewards: any[] = [];
  customersLoading = false;
  locationsLoading = false;
  messageLoading = false;
  saving = false;
  error = false;
  giveReward: boolean = false;
  form!: FormGroup;
  dateFormControl: FormControl = new FormControl();
  timeFormControl: FormControl = new FormControl('09:00');
  descriptionFormControl: FormControl = new FormControl();
  fromFormControl: FormControl = new FormControl([Validators.required]);
  isMobile = false;
  message: Message = {};
  scrollTimeout: any;
  suppressPaging: boolean = false;
  cursorPosition = 0;
  windowWidth = window.innerWidth;
  searchTerm = '';
  planLimitsLoading = false;
  canPush = false;
  aiActive = false;
  canSchedule = false;
  minDate: string = new Date().toISOString();
  fromOptions: SendAs[] = [];
  locations: Location[] = [];

  times: string[] = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
  ];

  contentMaxChar = 180;

  @HostListener('window:resize', ['$event'])
  onResize = () => this.testMobile();

  testMobile() {
    this.windowWidth = window.innerWidth;
    this.isMobile = window.innerWidth < 768 ? true : false;
  }

  filters: any = {
    birthdayThisMonth: false,
    receivedReward: false,
    redeemedReward: false,
    receivedStamp: false,
    receivedNotification: false,
  };

  selectedCards = [];

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public constants: Constants,
    private authService: AuthService,
    private merchantService: MerchantService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mixpanel: AnalyticsAbstract,
    private locationService: LocationService
  ) {
    this.isMobile = window.innerWidth < 768 ? true : false;
    this.form = this.fb.group({
      message: this.descriptionFormControl,
      from: this.fromFormControl,
      date: this.dateFormControl,
    });

    this.activatedRoute.params.subscribe(
      (params) => (this.message.nanoid = params['id'])
    );

    let currentDate: Date = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    this.minDate = currentDate.toISOString().split('T')[0];

    this.dateFormControl.setValue(this.minDate);
  }

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');

    this.getCustomers();
    this.getPlanLimits();

    if (this.message?.nanoid) {
      this.getMessage();
    } else {
      this.getLocations();
    }
  }

  getPlanLimits(): void {
    this.planLimitsLoading = true;
    this.merchantService
      .getPlanLimits()
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.canPush = res.push > 0;
            this.aiActive = res.ai;
            this.canSchedule = res.schedulePn;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.planLimitsLoading = false));
  }

  get containerOffsetFactor(): number {
    if (window.innerWidth < 600) {
      return 0.09;
    } else if (window.innerWidth < 768) {
      return 0.06;
    } else if (window.innerWidth < 863) {
      return 0.06;
    } else if (window.innerWidth < 960) {
      return 0.08;
    } else if (window.innerWidth < 992) {
      return 0.06;
    } else {
      return 0.03;
    }
  }

  get emptyString(): string {
    return `No ${this.constants.strings.customers} to display`;
  }

  goForward(stepper: MatStepper) {
    if (stepper.selectedIndex === 2) {
      this.confirm();
    } else {
      stepper.next();
    }
  }

  goBack(stepper: MatStepper) {
    if (stepper.selectedIndex === 0) {
      this.router.navigate([Constants.routes.engage]);
    } else {
      stepper.previous();
    }
  }

  canReview(): boolean {
    if (!this.descriptionFormControl.value) {
      return false;
    }

    if (this.giveReward && !this.rewards?.length) {
      return false;
    }
    return true;
  }

  getMessage(): void {
    this.messageLoading = true;
    this.merchantService
      .getMessage(this.message?.nanoid)
      .subscribe({
        next: (res: Message) => {
          if (res) {
            this.message = res;
            this.descriptionFormControl.setValue(res.message);
            if (res.reward) {
              this.giveReward = true;
              this.rewards = [{ reward: res.reward }];
            }

            if (res.schedule && res.schedule?.date) {
              this.sendOption = 1;

              let selectedDate = new Date(res.schedule?.date);
              let formattedDate = selectedDate.toISOString().split('T')[0];
              this.dateFormControl.setValue(formattedDate);

              let selectedUKTime = this.getUKTimeString(selectedDate);
              let selectedTime = this.times.find((el) => el === selectedUKTime);
              this.timeFormControl.setValue(selectedTime);
            }
            this.getLocations();
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.messageLoading = false));
  }

  getCustomers(): void {
    this.customersLoading = true;
    this.merchantService
      .getCustomers(
        '_id',
        'desc',
        this.pageSize,
        this.pageIndex,
        '',
        this.filters,
        this.selectedCards
      )
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.length = res.totalDocs;
            this.customers = res.docs;
            this.originalCustomers = res.docs;

            this.customers.forEach((el) => {
              if (
                this.message.recipientList?.includes(
                  (el.user as User)?._id ?? ''
                )
              ) {
                el.checked = true;
              }
            });
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.customersLoading = false));
  }

  getLocations(): void {
    this.locationsLoading = true;

    this.locationService
      .getLocations(1000, 0)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.locations = res.docs.filter(
              (el: Location) => el.active === true && el.approved === true
            );

            const merchantName = this.authService.getNestedUserProperty(
              'merchant',
              'name'
            );
            if (merchantName) {
              this.fromOptions.push({
                name: merchantName,
                type: 'merchant',
              });
            }

            if (this.locations?.length) {
              this.locations.forEach((el: Location) => {
                this.fromOptions.push({
                  name: el.name!,
                  type: 'location',
                });
              });
            }

            if (this.message.from) {
              this.fromFormControl.setValue(
                this.fromOptions.find(
                  (el) =>
                    el.name == this.message.from?.name &&
                    el.type == this.message.from?.type
                )
              );
            } else {
              this.fromFormControl.setValue(this.fromOptions[0]);
            }

            if (
              this.fromOptions?.length === 1 ||
              (this.fromOptions?.length === 2 &&
                this.fromOptions[0].name === this.fromOptions[1].name)
            ) {
              this.fromFormControl.disable();
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => {
        this.locationsLoading = false;
      });
  }

  isUser(user?: string | User): user is User {
    return (
      typeof user !== 'string' &&
      user !== null &&
      typeof user?.name === 'string'
    );
  }

  toggleReward(): void {
    this.giveReward = !this.giveReward;

    if (!this.giveReward) {
      this.rewards = [];
    }
  }

  confirm(): void {
    const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: 'Confirm',
        body: `This push notification will be sent to ${
          this.selectedCustomers?.length
        } customers ${
          this.sendOption === 1
            ? ' on ' + new Date(this.getScheduledDateString())
            : ''
        } <i>(as long as they have their mobile notifications turned on)</i>, do you wish to continue?`,
        buttonOne: 'Cancel',
        buttonTwo: 'Yes',
      },
      scrollStrategy: new NoopScrollStrategy(),
      maxWidth: '400px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
    });
    dialogRef.afterClosed().subscribe((option: number) => {
      if (option == 1) {
        this.send();
      }
    });
  }

  send(draft: boolean = false): void {
    const message = this.descriptionFormControl.value?.trim();
    const from = this.fromFormControl.value?.name;

    if (draft && !message) {
      this.constants.snack('Please enter a message');
      return;
    }

    if (!from) {
      this.constants.snack('Please select who to send this as');
      return;
    }

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.saving = true;

    const fromValue = this.fromFormControl.value;

    const payload: {
      logo: File | undefined;
      from: SendAs;
      message: string;
      reward: string | undefined;
      schedule: Date | undefined;
      timezone: string | undefined;
      recipients: any[];
      messageId: string | undefined;
    } = {
      logo: undefined,
      from: fromValue,
      message: message,
      reward: undefined,
      schedule: undefined,
      timezone: undefined,
      recipients: [],
      messageId: undefined,
    };

    if (fromValue.type == 'location') {
      const location = this.locations.find((el) => el.name == fromValue.name);
      if (location) {
        payload.logo = location.logo;
      }
    }

    payload.recipients = [
      ...new Set(
        this.customers
          .filter((el) => el.checked === true)
          .map((el) => (this.isUser(el.user) ? el.user._id : el.user))
      ),
    ];

    if (this.message.draft) {
      payload.messageId = this.message._id!;
    } else if (!draft) {
      if (this.message.draft) {
        payload.messageId = this.message._id!;
      }
    }

    this.mixpanel.track(
      draft
        ? Constants.analytics_keys.savePushNotification
        : Constants.analytics_keys.sendPushNotification,
      {
        Message: message,
      }
    );

    if (this.rewards?.length) {
      payload.reward = this.rewards[0]?.reward?._id;
    }

    if (this.sendOption === 1 && !draft) {
      if (!this.dateFormControl.value || !this.timeFormControl.value) {
        this.constants.snack('Invalid schedule date/time');
        return;
      }

      payload.schedule = new Date(this.getScheduledDateString());
      payload.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    this.merchantService
      .sendPush(payload, draft)
      .subscribe({
        next: (res: any) => {
          if (res) {
            let string1 =
              'Your push notification has been successfully sent to the selected customers';
            let string2 = 'Message Sent';

            if (draft) {
              string1 =
                'Your push notification has been saved to drafts, you can resume it later';
              string2 = 'Message Saved';
            } else if (payload.schedule) {
              string1 = `Your push notification has been scheduled to be sent on <b>${payload.schedule}</b>`;
              string2 = 'Message Scheduled';
            }
            this.showSuccessAlert(string1, string2);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  getScheduledDateString() {
    const localDateTimeString = `${this.dateFormControl.value}T${this.timeFormControl.value}:00`;

    const localDateTime = new Date(localDateTimeString);

    const utcDateTime = new Date(
      localDateTime.getTime() - localDateTime.getTimezoneOffset() * 60000
    );

    return utcDateTime.toISOString().slice(0, -1);
  }

  showSuccessAlert(str: string, title: string): void {
    const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: title,
        body: str,
        buttonOne: '',
        buttonTwo: 'OK',
      },
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
      width: '300px',
    });
    dialogRef.afterClosed().subscribe((option: number) => {
      if (option == 1) {
        this.router.navigate([Constants.routes.engage]);
      }
    });
  }

  pageEvent(event: any) {}

  rowTappedNGX({ row, type }: any) {
    if (type === 'click') {
      let isAlreadySelected =
        this.customers.find((el) => el._id === row._id)?.checked ?? false;
      this.checkSingle(!isAlreadySelected, row._id);
    }
  }

  nextDisabled(stepper: MatStepper): boolean {
    if (this.loading) {
      return true;
    }

    if (stepper.selectedIndex === 1 && !this.canReview()) {
      return true;
    }

    if (
      stepper.selectedIndex === 2 &&
      (!this.selectedCustomers?.length || !this.canPush)
    ) {
      return true;
    }

    return false;
  }

  getTooltip(stepper: MatStepper): string {
    if (!this.nextDisabled(stepper)) {
      return '';
    }

    if (stepper.selectedIndex === 1) {
      if (this.giveReward && !this.rewards?.length) {
        return 'Please select a reward';
      } else {
        return 'Please compose your message';
      }
    }

    if (stepper.selectedIndex === 2) {
      if (!this.canPush) {
        return 'Please upgrade your plan to send more push notifications';
      } else {
        return 'Please select at least one recipient';
      }
    }

    return '';
  }

  checkSingle(checked: boolean, id: any): void {
    const customer = this.customers.find((customer) => customer._id === id);

    if (customer) {
      customer.checked = checked;
    } else {
      console.error(`Customer with ID ${id} not found.`);
    }
  }

  checkAll(checked: boolean): void {
    this.customers.forEach((customer) => (customer.checked = checked));
  }

  get allChecked(): boolean {
    return this.customers.every((customer) => {
      customer.checked === true;
    });
  }

  get selectedCustomers(): any {
    return this.customers.filter((customer) => customer.checked === true);
  }

  openRewardDialog(): void {
    const dialogRef = this.dialog.open(RewardDialogComponent, {
      data: {
        exclusions: this.rewards,
      },
      width: '450px',
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.rewards.push(data);
      }
    });
  }

  removeReward(item: any): void {
    const index = this.rewards.findIndex((el) => el === item);
    if (index !== -1) {
      this.rewards.splice(index, 1);
      this.giveReward = false;
    }
  }

  get loading(): boolean {
    return (
      this.messageLoading ||
      this.customersLoading ||
      this.planLimitsLoading ||
      this.locationsLoading
    );
  }

  filter(): void {
    this.mixpanel.track(Constants.analytics_keys.openRecipientsFilter);

    const dialogRef = this.dialog.open(RecipientsFilterDialog, {
      width: '450px',
      maxHeight: '85vh',
      autoFocus: false,
      data: {
        filters: this.filters,
        cards: this.selectedCards,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.searchTerm = '';

        if (data?.filters) {
          this.filters = data?.filters;
        }

        if (data?.cards) {
          this.selectedCards = data.cards;
        }

        this.getCustomers();
      }
    });
  }

  get hasFilters(): number {
    let filterCount = 0;

    Object.values(this.filters).forEach((el: any) => {
      if (el === true) {
        filterCount++;
      }
    });

    if (this.selectedCards?.length) {
      filterCount++;
    }

    return filterCount;
  }

  clearFilters(): void {
    Object.keys(this.filters).forEach((el: any) => {
      this.filters[el] = false;
    });

    this.selectedCards = [];

    this.getCustomers();
  }

  openEmojiPicker(): void {
    this.mixpanel.track(Constants.analytics_keys.openEmojiPicker);

    const dialogRef = this.dialog.open(EmojiDialogComponent, {
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data?.emoji) {
        this.insertEmojiAtCursor(data.emoji);
      }
    });
  }

  insertEmojiAtCursor(emoji: string) {
    const currentValue = this.descriptionFormControl.value;
    const newValue =
      currentValue.slice(0, this.cursorPosition) +
      emoji +
      currentValue.slice(this.cursorPosition);
    this.descriptionFormControl.setValue(newValue);
  }

  updateCursorPosition(event: any) {
    this.cursorPosition = event.target.selectionStart;
  }

  handleScroll() {
    this.suppressPaging = true;

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPaging = false;
    }, 100);
  }

  openAIDialog(): void {
    this.mixpanel.track(Constants.analytics_keys.openAIGenerator);

    const dialogRef = this.dialog.open(AIDialogComponent, {
      width: '600px',
      autoFocus: false,
      disableClose: true,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.mixpanel.track(Constants.analytics_keys.generateAIMessage, {
          Message: data,
        });

        this.descriptionFormControl.setValue(data);
      }
    });
  }

  searchCustomers(): void {
    if (this.searchTerm !== '') {
      this.customers = this.customers.filter((el) => {
        if (this.isUser(el.user)) {
          return el.user.name
            ?.toLowerCase()
            .includes(this.searchTerm.toLowerCase());
        } else {
          return false;
        }
      });
    } else {
      this.customers = this.originalCustomers;
    }
  }

  getUKTimeString(date: Date): string {
    const ukTimeFormat = new Intl.DateTimeFormat('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Europe/London',
      hour12: false,
    });

    const parts = ukTimeFormat.formatToParts(date);
    const hour = parts
      .find((part) => part.type === 'hour')
      ?.value.padStart(2, '0');
    const minute = parts
      .find((part) => part.type === 'minute')
      ?.value.padStart(2, '0');

    return `${hour}:${minute}`;
  }

  toggleSendBasis(basis: number, checked: boolean) {
    if (basis === 0) {
      this.sendOption = checked ? 0 : 1;
    } else {
      this.sendOption = checked ? 1 : 0;
    }
  }
}
