<div class="container-fluid" *ngIf="!pageLoading">
  <div class="row">
    <div class="col-md-12" id="left-col">
      <div class="left-content">
        <div class="form-container">
          <div class="img-container">
            <a href="https://remyrewards.co.uk"
              ><img src="../assets/remylogo.png"
            /></a>
          </div>

          <form [formGroup]="loginForm">
            <div class="form-field" style="margin-top: 20px">
              <div class="form-field-label" style="color: var(--app-timber)">
                Email
              </div>
              <input
                class="form-input"
                [formControl]="emailFormControl"
                type="email"
              />
            </div>
            <div class="form-field" style="margin-top: 20px">
              <div class="form-field-label" style="color: var(--app-timber)">
                Password
              </div>
              <div style="display: flex; align-items: center">
                <input
                  [type]="hidePassword ? 'password' : 'text'"
                  class="form-input"
                  formControlName="password"
                />
                <mat-icon
                  class="visibility-icon"
                  (click)="hidePassword = !hidePassword"
                  >{{
                    hidePassword ? "visibility_off" : "visibility"
                  }}</mat-icon
                >
              </div>
            </div>
          </form>

          <div class="action-buttons" style="margin-top: 20px">
            <app-secondary-button
              style="margin-right: 10px"
              [title]="'Forgot password'"
              [disabled]="false"
              color="darkGrey"
              (tapped)="navigateToForgotPassword()"
            ></app-secondary-button>
            <app-primary-button
              [title]="'Sign in'"
              [disabled]="loadingOne || loginForm.invalid"
              (tapped)="login()"
              bgColor="var(--app-timber)"
              color="white"
            ></app-primary-button>
          </div>

          <!-- <div
            class="form-field"
            style="display: flex; justify-content: center"
          >
            <button class="forgot-password">
              Don't have an account?
              <span style="color: blue" (click)="signup()">Sign up</span>
            </button>
          </div> -->

          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 40px;
            "
          >
            <app-primary-button
              [title]="'Sign in with Magic Link'"
              [disabled]="loadingOne"
              (tapped)="magicLink()"
              border="1px solid var(--app-timber)"
              color="var(--app-timber)"
            ></app-primary-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<loader [loading]="pageLoading || loadingOne"></loader>
