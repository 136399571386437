import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../services/index';
import { Constants } from '../app.constants';

@Injectable({ providedIn: 'root' })
export class OwnerGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate() {
    const user = this.authService.getUserProperty('token');

    if (user && this.authService.isOwnerOrAdmin) {
      return true;
    }

    this.router.navigate(['/' + Constants.routes.dashboard], {}).then(() => {
      window.location.reload();
    });

    return false;
  }
}
