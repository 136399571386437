<dialog-header
  title="Request an NFC card"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div style="min-height: 200px">
  <div
    mat-dialog-content
    style="
      padding-bottom: 20px;
      border-top: 1px solid lightgray;
      border-bottom: 1px solid lightgray;
      background-color: var(--app-container);
    "
  >
    <form [formGroup]="form" style="margin-top: 20px">
      <div class="form-field-label" style="margin-bottom: 15px">
        Choose your card design
      </div>
      <nfc-options-container
        (optionTapped)="colorOptionTapped($event)"
      ></nfc-options-container>

      <!-- <div class="form-field-label">Choose a color for your card</div>
      <single-select
        [formCtrl]="colorFormControl"
        [options]="colors"
        [formCtrl]="colorFormControl"
      ></single-select> -->

      <div class="form-field" style="margin-top: 20px">
        <single-select
          label="Where should we send your card?"
          [formCtrl]="destinationFormControl"
          [options]="destinations"
          [property]="'label'"
          [value]="destinationFormControl.value"
          (selected)="selectedDestinationType()"
        ></single-select>
      </div>

      <!-- New Address-->
      <div
        *ngIf="destinationFormControl.value?.value === destinationEnum.address"
      >
        <div class="form-field" style="margin-top: 20px">
          <single-select
            label="Country"
            *ngIf="countries?.length"
            [formCtrl]="countryFormControl"
            [options]="countries"
            property="name"
            [value]="countryFormControl.value"
          ></single-select>
        </div>

        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label">
            First line<span style="color: red">*</span>
          </div>
          <input
            class="form-input"
            autocomplete="off"
            [formControl]="lineOneFormControl"
            type="text"
          />
        </div>
        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label">Second line</div>
          <input
            class="form-input"
            autocomplete="off"
            [formControl]="lineTwoFormControl"
            type="text"
          />
        </div>
        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label">
            Town/City<span style="color: red">*</span>
          </div>
          <input
            class="form-input"
            autocomplete="off"
            [formControl]="cityFormControl"
            type="text"
          />
        </div>
        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label">
            Postcode<span style="color: red">*</span>
          </div>
          <input
            class="form-input"
            autocomplete="off"
            [formControl]="postcodeFormControl"
            type="text"
          />
        </div>
      </div>

      <!-- Existing Location-->
      <div
        *ngIf="destinationFormControl.value?.value === destinationEnum.location"
      >
        <div class="form-field" style="margin-top: 20px">
          <single-select
            label="Select location"
            [formCtrl]="locationFormControl"
            [options]="locations"
            [property]="'name'"
            [value]="locationFormControl.value"
            (selected)="locationSelected($event)"
          ></single-select>
        </div>
      </div>
    </form>
  </div>

  <div mat-dialog-actions align="end" *ngIf="!loading">
    <button
      [disabled]="false"
      (click)="matDialogRef.close()"
      mat-flat-button
      class="secondary-button"
      style="margin-right: 10px"
    >
      <span>Cancel</span>
    </button>
    <button
      [disabled]="false"
      (click)="primaryTapped()"
      mat-flat-button
      class="primary-button"
    >
      <span>Send Request</span>
    </button>
  </div>

  <loader [loading]="loading"></loader>
</div>

<loader [loading]="locationsLoading"></loader>
