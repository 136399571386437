import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';

import {
  MerchantService,
  AuthService,
  ExcelService,
} from '../../../services/index';
import { Customer } from '../../../models/index';

@Component({
  selector: 'customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css'],
})
export class CustomersComponent {
  @Input() smallTitle = false;
  @Input() title = '';
  @Input() subtitle: string = '';
  @ViewChild('myTable') table: any;

  pageIndex = 0;
  pageSize = 30;
  length = 0;
  customers: Customer[] = [];
  loading = false;
  error = false;
  exporting = false;
  searching = false;
  scrollTimeout: any;
  suppressPaging: boolean = false;
  customersRange = '';
  searchTerm = '';

  sortKey = '_id';
  sortDir = 'desc';

  constructor(
    public constants: Constants,
    private authService: AuthService,
    private merchantService: MerchantService,
    private router: Router,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');

    this.loading = true;
    this.getCustomers();
  }

  get emptyString(): string {
    return `No ${this.constants.strings.customers} to display`;
  }

  getCustomers(): void {
    this.merchantService
      .getCustomers(
        this.sortKey,
        this.sortDir,
        this.pageSize,
        this.pageIndex,
        this.searchTerm,
        {}
      )
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.length = res.totalDocs;
            this.customers = res.docs;

            this.customersRange = this.constants.pageCounter(
              this.pageIndex,
              this.pageSize,
              this.length,
              this.customers?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => ((this.loading = false), (this.searching = false)));
  }

  pageEvent(event: any) {
    if (!this.suppressPaging) {
      this.pageIndex = event.offset;
      this.searching = true;
      this.getCustomers();
    }
  }

  rowTappedNGX({ row, type }: any) {
    if (type === 'click') {
      this.router.navigate([Constants.routes.customer + '/' + row.nanoid]);
    }
  }

  handleScroll() {
    this.suppressPaging = true;

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPaging = false;
    }, 100);
  }

  export(): void {
    this.exporting = true;
    this.merchantService
      .export('customers')
      .subscribe({
        next: (res: any) => {
          if (res.data && res.data.length === 0) {
            this.constants.snack('No customers to export');
          } else {
            this.excelService.exportExcelFile(res.data.data, res.fileName);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.exporting = false));
  }

  searchCustomers(): void {
    this.pageIndex = 0;
    this.searching = true;
    this.getCustomers();
  }

  cancel(): void {
    this.pageIndex = 0;
    this.searchTerm = '';
    this.searching = true;
    this.getCustomers();
  }

  onSort(event: any): void {
    if (event.sorts && event.sorts.length > 0) {
      const sort = event.sorts[0];
      this.sortKey = sort.prop;
      this.sortDir = sort.dir;
      this.searching = true;
      this.getCustomers();
    }
  }
}
