<div
  class="d-flex justify-content-between align-items-md-center align-items-baseline"
>
  <p
    class="section-title"
    style="margin-bottom: 0px; font-weight: bold; font-size: 16px"
  >
    Magic Link Login
  </p>

  <div style="display: flex">
    <button mat-button (click)="matDialogRef.close()">
      <mat-icon class="db-icon" style="color: grey">close</mat-icon>
    </button>
  </div>
</div>

<div mat-dialog-content>
  <p
    class="section-subtitle"
    style="
      margin-bottom: 0px;
      margin-top: 10px;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: grey;
    "
  >
    Long password? Hard to type? We can email you a magic link so you can sign
    in without having to type your password
  </p>

  <div class="form-field" style="margin-top: 20px">
    <div class="form-field-label">Email<span style="color: red">*</span></div>
    <input class="form-input" [formControl]="emailFormControl" type="text" />
  </div>
</div>

<div mat-dialog-actions align="end" *ngIf="!loading">
  <app-secondary-button
    style="margin-right: 10px"
    [title]="'Cancel'"
    [disabled]="false"
    (tapped)="matDialogRef.close()"
  ></app-secondary-button>

  <app-primary-button
    [title]="'Submit'"
    [disabled]="form.invalid"
    (tapped)="save()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>

<loader [loading]="saving || loading"></loader>
