<dialog-header
  title="Select Reward"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  *ngIf="!loading"
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <form [formGroup]="form" style="margin-top: 20px">
    <div *ngIf="data?.stampNumbers">
      <single-select
        label="Stamp Number"
        [formCtrl]="numberFormControl"
        property="number"
        [options]="data?.stampNumbers"
        [showIcon]="false"
      ></single-select>
    </div>

    <div class="form-field" style="margin-top: 20px">
      <single-select
        label="Reward"
        [formCtrl]="rewardFormControl"
        [options]="rewards"
        property="title"
        [showIcon]="false"
        (click)="hasRewards()"
      ></single-select>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    style="margin-right: 10px"
    [title]="'Cancel'"
    [disabled]="false"
    (tapped)="matDialogRef.close()"
  ></app-secondary-button>

  <app-primary-button
    [title]="'Save'"
    [disabled]="false"
    (tapped)="primaryTapped()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>

<loader [loading]="loading"></loader>
