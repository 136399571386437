import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { Location } from '../../../models/index';
import {
  LocationService,
  MerchantService,
  AuthService,
} from '../../../services/index';

enum Destination {
  location,
  address,
}

@Component({
  selector: 'request-nfc-dialog',
  templateUrl: './request-nfc-dialog.component.html',
  styleUrls: ['./request-nfc-dialog.component.css'],
})
export class RequestNFCDialogComponent implements OnInit {
  form!: FormGroup;

  countries: string[] = [];
  destinations: { label: string; value: Destination }[] = [];

  colorFormControl!: FormControl;
  destinationFormControl!: FormControl;
  locationFormControl!: FormControl;
  countryFormControl!: FormControl;
  lineOneFormControl!: FormControl;
  lineTwoFormControl!: FormControl;
  cityFormControl!: FormControl;
  postcodeFormControl!: FormControl;

  colors: any = ['White', 'Black'];
  locations: Location[] = [];
  loading = true;
  locationsLoading = false;
  destinationEnum: typeof Destination = Destination;

  constructor(
    private constants: Constants,
    private http: HttpClient,
    public matDialogRef: MatDialogRef<RequestNFCDialogComponent>,
    public fb: FormBuilder,
    private authService: AuthService,
    private merchantService: MerchantService,
    private locationService: LocationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.destinations = [
      { label: 'A new address', value: Destination.address },
    ];

    this.http
      .get<any>('../../../../assets/json/countries.json')
      .subscribe((data) => {
        this.countries = data;

        this.countryFormControl = new FormControl(
          { value: { name: '', code: '' }, disabled: false },
          [Validators.required]
        );
        this.lineOneFormControl = new FormControl({
          value: '',
          disabled: false,
        });
        this.lineTwoFormControl = new FormControl({
          value: '',
          disabled: false,
        });
        this.cityFormControl = new FormControl({ value: '', disabled: false });
        this.postcodeFormControl = new FormControl(
          {
            value: '',
            disabled: false,
          },
          [Validators.required]
        );

        this.colorFormControl = new FormControl(
          {
            value: 'White',
            disabled: false,
          },
          [Validators.required]
        );

        this.destinationFormControl = new FormControl(
          {
            value: null,
            disabled: false,
          },
          [Validators.required]
        );

        this.locationFormControl = new FormControl({
          value: '',
          disabled: false,
        });

        this.form = this.fb.group({
          color: this.colorFormControl,
          destination: this.destinationFormControl,
          location: this.locationFormControl,
          country: this.countryFormControl,
          lineOne: this.lineOneFormControl,
          lineTwo: this.lineTwoFormControl,
          city: this.cityFormControl,
          postcode: this.postcodeFormControl,
        });

        setTimeout(() => {
          this.loading = false;
        }, 500);
      });

    this.getLocations();
  }

  ngOnInit(): void {}

  colorOptionTapped(option: number) {
    this.colorFormControl.setValue(option === 0 ? 'White' : 'Black');
  }

  primaryTapped() {
    if (this.form?.invalid) {
      this.constants.snack('Please complete all required fields');
      this.form.markAllAsTouched();

      Object.keys(this.form.controls).forEach((field) => {
        const control = this.form.get(field);
        if (control?.invalid) {
          console.log(
            `Field: ${field}, Errors: ${JSON.stringify(control.errors)}`
          );
        }
      });
      return;
    }
    this.matDialogRef.close({
      color: this.colorFormControl.value,
      country: this.countryFormControl.value?.name,
      lineOne: this.lineOneFormControl.value,
      lineTwo: this.lineTwoFormControl.value,
      city: this.cityFormControl.value,
      postcode: this.postcodeFormControl.value,
    });
  }

  selectedDestinationType() {
    const selection = this.destinations.find(
      (el) => el.label === this.destinationFormControl.value.label
    );

    if (selection?.value === Destination.location) {
      this.locationFormControl.setValidators([Validators.required]);
    } else {
      this.locationFormControl.clearValidators();
      this.lineOneFormControl.setValidators([Validators.required]);
    }
  }

  locationSelected(location: Location) {
    this.countryFormControl.setValue({ name: location.address?.country });
    this.cityFormControl.setValue(location.address?.city);
    this.lineOneFormControl.setValue(location.address?.lineOne);
    this.lineTwoFormControl.setValue(location.address?.lineTwo);
    this.postcodeFormControl.setValue(location.address?.postcode);

    if (location.address?.type === 1) {
      //private premises
      this.lineOneFormControl.clearValidators();
      this.lineTwoFormControl.clearValidators();
    } else {
      this.lineOneFormControl.setValidators([Validators.required]);
      this.lineTwoFormControl.setValidators([Validators.required]);
    }
  }

  getLocations(): void {
    this.locationsLoading = true;

    const subscription =
      this.authService.getUserProperty('userType') ===
      this.constants.userTypes.manager
        ? this.merchantService.getStaffLocations()
        : this.locationService.getLocations(1000, 0);

    subscription
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.locations = res?.docs ?? res;
            this.locations?.sort((a, b) => a.name!.localeCompare(b.name!));

            if (this.locations?.length) {
              this.destinations.unshift({
                label: 'One of my saved locations',
                value: Destination.location,
              });
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.locationsLoading = false));
  }
}
