<page-header
  [title]="constants.strings.rewards"
  [subtitle]="subtitle"
></page-header>

<div style="display: flex; justify-content: flex-end; margin-bottom: 10px">
  <app-primary-button
    [title]="constants.strings.newReward"
    [disabled]="false"
    (tapped)="newReward()"
    [icon]="'add'"
  ></app-primary-button>
</div>

<div *ngIf="!loading">
  <ngx-datatable
    #myTable
    class="material"
    [headerHeight]="45"
    [rows]="rewards"
    [scrollbarV]="false"
    [scrollbarH]="true"
    [footerHeight]="50"
    [messages]="{
      emptyMessage:
        '<a href=https://app.remyrewards.co.uk/reward>+ Add your first Reward</a>'
    }"
    [columnMode]="'force'"
    [rowHeight]="48"
    [externalPaging]="true"
    [count]="rewards.length"
    [offset]="pageIndex"
    (page)="pageEvent($event)"
    (scroll)="handleScroll()"
    [limit]="pageSize"
    (activate)="rowTappedNGX($event)"
  >
    <ngx-datatable-column name="Created" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.createdAt | date : constants.longDate }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Title" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.title }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Description" [resizeable]="true">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.description | truncate : 45 }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Location"
      [resizeable]="false"
      *ngIf="locationCount > 1"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{
          row.locations?.length === 1
            ? row.locations[0]?.name
            : row.locations?.length + " Locations"
        }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Expiry" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.expiry === 0 ? "No Expiry" : row?.expiry + " Days" }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Value" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{
          row?.value
            ? (row?.value | number : "1.2-2")
            : row?.value == 0
            ? "-"
            : "-"
        }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Giftable" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <mat-icon
          class="status-icon"
          style="color: green"
          *ngIf="row.giftable ?? true"
          >check_circle</mat-icon
        >
        <mat-icon
          class="status-icon"
          style="color: red"
          *ngIf="!(row.giftable ?? true)"
          >cancel</mat-icon
        >
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-offset="offset"
        let-isVisible="isVisible"
      >
        <div class="page-count" *ngIf="length > 0">
          <span>{{ rewardRange }}</span>
        </div>
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="pageIndex + 1"
          [size]="pageSize"
          [count]="length"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

<loader [loading]="loading"></loader>
