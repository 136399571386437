<div mat-dialog-content>
  <div *ngIf="step === 1">
    <p class="onboard-title">{{ "Hi " + name }} 👋</p>
    <p class="onboard-subtitle">
      We're so excited to have you onboard, your trial period has now
      started.<br /><br />You can get setup right away by adding you first
      Location, Rewards, Stamp Cards and optional Team Member(s).<br /><br />
      You can also request an NFC card if this is something you'd like to use in
      your location(s).<br /><br />
      We will just need to approve your merchant profile before it can be listed
      in our app, but rest assured this will be well within 24 hours, we'll
      contact you via email to confirm when it's all live.
      <br /><br />Remember, you can always get in touch using the web chat at
      the bottom right corner of this screen or by emailing us at
      <a href="mailto:hello@remyrewards.co.uk" target="_blank">{{
        "hello@remyrewards.co.uk"
      }}</a>
    </p>
  </div>

  <div *ngIf="step === 2">
    <div
      style="
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      "
    >
      <p class="onboard-title">What happens after the trial?</p>
      <p class="onboard-subtitle">
        As long as you upgrade to one of our paid plans before the end of your
        trial period, you'll be able to continue using Remy as normal.<br /><br />
        Should you wish to not continue, then just let your trial expire - this
        will however mean that your locations will no longer be discoverable and
        any customer stamp cards & rewards will be de-activated.
      </p>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    *ngIf="step > 1"
    [disabled]="saving"
    [title]="'Back'"
    (tapped)="backTapped()"
  ></app-secondary-button>
  <app-primary-button
    [disabled]="saving"
    [title]="step === 2 ? 'Finish' : 'Next'"
    (tapped)="primaryTapped()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>

<loader [loading]="saving"></loader>
