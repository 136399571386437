<page-header
  [title]="constants.strings.stampCards"
  [subtitle]="subtitle"
></page-header>

<div style="display: flex; justify-content: flex-end; margin-bottom: 10px">
  <app-primary-button
    [title]="constants.strings.newCard"
    [disabled]="loading"
    (tapped)="newCard()"
    [icon]="'add'"
  ></app-primary-button>
</div>

<div *ngIf="!cardsLoading">
  <ngx-datatable
    #myTable
    class="material"
    [headerHeight]="45"
    [rows]="cards"
    [scrollbarV]="false"
    [messages]="{
      emptyMessage:
        '<a href=https://app.remyrewards.co.uk/card>+ Add your first Stamp Card</a>'
    }"
    [scrollbarH]="true"
    [footerHeight]="50"
    [columnMode]="'force'"
    [rowHeight]="48"
    [externalPaging]="true"
    [count]="length"
    [offset]="pageIndex"
    (page)="pageEvent($event)"
    (scroll)="handleScroll()"
    [limit]="pageSize"
    (activate)="rowTappedNGX($event)"
  >
    <ngx-datatable-column name="Created" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.createdAt | date : constants.longDate }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Card" [resizeable]="true">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.title }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Description" [resizeable]="true">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.description | truncate : 45 }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Location"
      [resizeable]="false"
      *ngIf="locationCount > 1"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{
          row.locations?.length === 1
            ? row.locations[0]?.name
            : row.locations?.length + " Locations"
        }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Status" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div id="status-chip" [ngClass]="constants.statusChipClass(row)">
          {{ row?.active ? "Active" : "Inactive" }}
        </div>
      </ng-template>
    </ngx-datatable-column>

    <!-- <ngx-datatable-column name="Active" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <mat-icon class="status-icon" style="color: green" *ngIf="row.active"
          >check_circle</mat-icon
        >
        <mat-icon class="status-icon" style="color: red" *ngIf="!row.active"
          >cancel</mat-icon
        >
      </ng-template>
    </ngx-datatable-column> -->

    <ngx-datatable-column name="Restrictions" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <mat-icon
          class="status-icon"
          style="color: green"
          *ngIf="row.restrictions?.daily?.enabled"
          >check_circle</mat-icon
        >
        <mat-icon
          class="status-icon"
          style="color: red"
          *ngIf="!row.restrictions?.daily?.enabled"
          >cancel</mat-icon
        >
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Actions" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <mat-icon
          class="status-icon"
          style="color: var(--app-timber)"
          [mat-menu-trigger-for]="menu"
          (click)="$event.stopPropagation()"
          [matMenuTriggerData]="{ card: row }"
          >more_horiz</mat-icon
        >
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-offset="offset"
        let-isVisible="isVisible"
      >
        <div class="page-count" *ngIf="length > 0">
          <span>{{ cardsRange }}</span>
        </div>
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="pageIndex + 1"
          [size]="pageSize"
          [count]="length"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

<loader [loading]="loading"></loader>

<mat-menu #menu="matMenu" class="custom-menu">
  <ng-template matMenuContent let-card="card">
    <button
      [disabled]="false"
      class="menu-list-item"
      style="border-radius: 5px"
      (click)="
        handleMenuOptionSelection(
          card?.active ? deactivateTitle.title : activateTitle.title,
          card
        )
      "
      mat-menu-item
    >
      <mat-icon
        fontSet="material-icons-outlined"
        style="
          font-size: 16px;
          vertical-align: middle;
          display: flex;
          align-items: center;
          margin-right: 5px;
        "
        >{{
          card?.active ? deactivateTitle.icon : activateTitle.icon
        }}</mat-icon
      >
      {{ card?.active ? deactivateTitle.title : activateTitle.title }}
    </button>
  </ng-template>
</mat-menu>
