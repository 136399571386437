import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import {
  StorageService,
  MerchantService,
  AuthService,
  RewardService,
  LocationService,
} from '../../../services/index';
import { Location, Reward } from '../../../models/index';

@Component({
  selector: 'reward-detail',
  templateUrl: './reward-detail.component.html',
  styleUrls: ['./reward-detail.component.css'],
})
export class RewardDetailComponent {
  @Input() smallTitle = false;
  @Input() title = '';
  @Input() subtitle: string = '';

  hasExpiry?: boolean = false;
  giftable?: boolean = true;
  form!: FormGroup;
  titleFormControl: FormControl = new FormControl();
  descriptionFormControl: FormControl = new FormControl();
  hiddenTextFormControl: FormControl = new FormControl();
  valueFormControl: FormControl = new FormControl();
  expiryFormControl: FormControl = new FormControl();
  locationFormControl: FormControl = new FormControl();

  socials: any[] = [];
  reward: Reward = {};
  locations: Location[] = [];

  locationsLoading: boolean = false;
  editMode = false;
  rewardLoading = false;
  planLimitsLoading = false;
  saving = false;
  error = false;
  submitted = false;
  disabledLocations: boolean[] = [];
  planLimits: any;

  constructor(
    public constants: Constants,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private rewardService: RewardService,
    private authService: AuthService,
    private merchantService: MerchantService,
    private locationService: LocationService,
    private storageService: StorageService
  ) {
    this.route.params.subscribe(
      (params) => (this.reward.nanoid = params['id'])
    );

    if (this.reward.nanoid) {
      this.editMode = true;
    }

    this.titleFormControl = new FormControl(
      { value: '', disabled: false },
      Validators.required
    );
    this.descriptionFormControl = new FormControl(
      { value: '', disabled: false },
      Validators.required
    );
    this.hiddenTextFormControl = new FormControl({
      value: '',
      disabled: false,
    });
    this.valueFormControl = new FormControl({ value: '', disabled: false });
    this.expiryFormControl = new FormControl(
      { value: '', disabled: false },
      []
    );

    this.locationFormControl = new FormControl(
      { value: '', disabled: false },
      Validators.required
    );

    this.form = this.fb.group({
      title: this.titleFormControl,
      description: this.descriptionFormControl,
      hiddenText: this.hiddenTextFormControl,
      value: this.valueFormControl,
      expiry: this.expiryFormControl,
      locations: this.locationFormControl,
    });
  }

  get tooltipLabel(): string {
    if (this.loading) {
      return '';
    }
    if (!this.canCreateReward) {
      if (!this.locations.length) {
        return this.constants.strings.addLocation;
      } else {
        return this.constants.strings.upgradePlanNotice;
      }
    } else {
      return '';
    }
  }

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');

    if (!this.editMode) {
      this.getPlanLimits();
      this.getLocations();
    } else {
      this.getReward();
    }
  }

  get canCreateReward(): boolean {
    if (this.editMode) {
      if (this.locations?.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.planLimits.rewards > 0 && this.locations.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  toggleExpiry(): void {
    this.hasExpiry = !this.hasExpiry;

    if (this.hasExpiry) {
      this.expiryFormControl.setValidators([Validators.required]);
    } else {
      this.expiryFormControl.clearValidators();
    }
  }

  getPlanLimits(): void {
    this.planLimitsLoading = true;
    this.merchantService
      .getPlanLimits()
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.planLimits = res;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => {
        this.planLimitsLoading = false;
      });
  }

  pageEvent(event: any) {}

  get saveEnabled(): boolean {
    return !this.form.invalid;
  }

  save(): void {
    this.submitted = true;

    if (this.form.invalid) {
      this.constants.snack('Please complete all required fields');
      return;
    }

    if (this.hasExpiry && this.expiryFormControl.value <= 0) {
      this.constants.snack('Expiry days must be greater than 0');
      return;
    }

    if (this.valueFormControl.value < 0) {
      this.constants.snack('Value cannot be less than 0');
      return;
    }

    let locations = (this.locationFormControl.value as Location[])
      .filter((el) => el._id !== undefined && el._id !== null)
      .map((el) => el._id);

    if (!locations.length) {
      this.constants.snack('Please select at least one location');
      return;
    }

    this.saving = true;

    const payload = {
      title: this.titleFormControl.value?.trim(),
      description: this.descriptionFormControl.value?.trim(),
      hiddenText: this.hiddenTextFormControl.value?.trim(),
      value: this.valueFormControl.value ?? 0,
      expiry: this.hasExpiry ? this.expiryFormControl.value : 0,
      id: this.reward._id,
      giftable: this.giftable,
      locations: locations,
    };

    if (this.editMode) {
      this.submit(this.rewardService.editReward(payload), false);
    } else {
      this.submit(this.rewardService.createReward(payload), true);
    }
  }

  submit(observable: Observable<any>, isNew: boolean): void {
    observable
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.router.navigate([Constants.routes.rewards]).then(() => {
              if (isNew) {
                const existing = this.authService.getNestedUserProperty(
                  'merchant',
                  'onboard'
                );
                if (existing) {
                  this.authService.updateNestedProperty('merchant', 'onboard', {
                    location: existing.location ?? false,
                    reward: true,
                    card: existing.card ?? false,
                  });
                  this.storageService.set('checklistVisible', String(true));
                }
                window.location.reload();
              }
            });
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  getReward(): void {
    this.rewardLoading = true;
    this.rewardService
      .getReward(this.reward.nanoid!)
      .subscribe({
        next: (res: Reward) => {
          if (res) {
            this.reward = res;
            this.titleFormControl.setValue(res.title);
            this.descriptionFormControl.setValue(res.description);
            this.hiddenTextFormControl.setValue(res.hiddenText);
            this.valueFormControl.setValue(res.value);

            this.giftable = res.giftable;

            if (res.expiry ?? 0 > 0) {
              this.expiryFormControl.setValue(res.expiry);
              this.hasExpiry = true;
            } else {
              this.hasExpiry = false;
              this.expiryFormControl.setValue('');
              this.expiryFormControl.clearValidators();
            }

            this.reward.locations?.sort((a, b) =>
              a.name!.localeCompare(b.name!)
            );

            this.getLocations();
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.rewardLoading = false));
  }

  get loading(): boolean {
    return (
      this.planLimitsLoading ||
      this.rewardLoading ||
      this.saving ||
      this.locationsLoading
    );
  }

  get giftTooltip(): string {
    return `Enable to allow this reward to be gifted once, thereby transferring it to the new user. 
    
            Original recipient loses access to the reward`;
  }

  get rewardTooltip(): string {
    return `By default, rewards can be redeemed by NFC Cards. 
    
            Enable this setting to also allow for reward redemption by NFC Tags.`;
  }

  hasLocations(): void {
    if (!this.locations.length) {
      this.constants.snack('Please add a location first');
    }
  }

  getLocations(): void {
    this.locationsLoading = true;

    const subscription =
      this.authService.getUserProperty('userType') ===
      this.constants.userTypes.manager
        ? this.merchantService.getStaffLocations()
        : this.locationService.getLocations(1000, 0);

    subscription
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.locations = res?.docs ?? res;
            this.locations?.sort((a, b) => a.name!.localeCompare(b.name!));

            if (this.editMode) {
              this.locationFormControl.setValue(this.reward.locations);

              for (var location of this.reward.locations ?? []) {
                if (!this.locations.some((loc) => loc._id == location._id)) {
                  this.disabledLocations.push(true);
                }
              }
            } else {
              this.locationFormControl.setValue(this.locations);
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => {
        this.locationsLoading = false;
      });
  }

  back() {
    this.router.navigate([Constants.routes.rewards]);
  }
}
