<page-header [title]="'NFC ' + nfcRef" [subtitle]="subtitle"></page-header>

<div
  style="margin-top: 20px"
  *ngIf="!loading"
  style="
    background-color: var(--app-container);
    border-radius: 15px;
    padding: 20px;
    margin-top: 20px;
  "
>
  <mat-tab-group
    disableRipple="true"
    [animationDuration]="0"
    [(selectedIndex)]="selectedIndex"
    (selectedTabChange)="tabChanged($event.index)"
    class="remove-border-bottom"
  >
    <mat-tab label="Stamps">
      <ngx-datatable
        #stampsTable
        class="material"
        [headerHeight]="45"
        [rows]="nfcEvents"
        [scrollbarV]="false"
        [messages]="{ emptyMessage: 'No stamp events to display' }"
        [scrollbarH]="true"
        [footerHeight]="50"
        [columnMode]="'force'"
        [rowHeight]="48"
        [externalPaging]="true"
        [count]="stampLength"
        [offset]="stampPageIndex"
        (page)="stampPageEvent($event)"
        (scroll)="handleScroll()"
        [limit]="stampPageSize"
      >
        <ngx-datatable-column name="Stamp Date" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.createdAt | date : constants.longestDate }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Card" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/card', row?.card?.nanoid]"
              (click)="$event.stopPropagation()"
            >
              {{ row?.card?.title }}
            </a>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Customer" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/customer', row?.customer?.nanoid]"
              (click)="$event.stopPropagation()"
            >
              {{ row?.user?.name }}
            </a>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Stamp #" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.stampNumber }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Reward Stamp?" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <mat-icon
              class="status-icon"
              style="color: green"
              *ngIf="row.hasReward"
              >check_circle</mat-icon
            >
            <mat-icon
              class="status-icon"
              style="color: red"
              *ngIf="!row.hasReward"
              >cancel</mat-icon
            >
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="page-count" *ngIf="stampLength > 0">
              <span>{{ stampEventsRange }}</span>
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'datatable-icon-left'"
              [pagerRightArrowIcon]="'datatable-icon-right'"
              [pagerPreviousIcon]="'datatable-icon-prev'"
              [pagerNextIcon]="'datatable-icon-skip'"
              [page]="stampPageIndex + 1"
              [size]="pageSize"
              [count]="stampLength"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="stampsTable.onFooterPage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </mat-tab>

    <mat-tab label="Redemptions">
      <ngx-datatable
        #redemptionsTable
        class="material"
        [headerHeight]="45"
        [rows]="nfcRedemptions"
        [scrollbarV]="false"
        [scrollbarH]="true"
        [footerHeight]="50"
        [columnMode]="'force'"
        [messages]="{ emptyMessage: 'No redemptions to display' }"
        [rowHeight]="48"
        [externalPaging]="true"
        [count]="redemptionLength"
        [offset]="redemptionPageIndex"
        (page)="redemptionPageEvent($event)"
        (scroll)="handleScroll()"
        [limit]="redemptionPageSize"
      >
        <ngx-datatable-column name="Redemption Date" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.createdAt | date : constants.longestDate }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Reward" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/reward', row?.reward?.nanoid]"
              (click)="$event.stopPropagation()"
            >
              {{ row?.reward?.title }}
            </a>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Source" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{
              row?.card?.title
                ? row?.card?.title
                : row?.birthdayClub
                ? "🎉 Birthday Club"
                : "Push Notification"
            }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Customer" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              [routerLink]="['/customer', row?.customer?.nanoid]"
              (click)="$event.stopPropagation()"
            >
              {{ row?.user?.name }}
            </a>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="page-count" *ngIf="redemptionLength > 0">
              <span>{{ redemptionsRange }}</span>
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'datatable-icon-left'"
              [pagerRightArrowIcon]="'datatable-icon-right'"
              [pagerPreviousIcon]="'datatable-icon-prev'"
              [pagerNextIcon]="'datatable-icon-skip'"
              [page]="redemptionPageIndex + 1"
              [size]="pageSize"
              [count]="redemptionLength"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="redemptionsTable.onFooterPage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </mat-tab>
  </mat-tab-group>
</div>

<loader [loading]="loading"></loader>
