<div *ngIf="!loading">
  <page-header [title]="title" [subtitle]="subtitle"></page-header>

  <div style="display: flex; justify-content: flex-end; margin-bottom: 10px">
    <app-primary-button
      [title]="constants.strings.giveReward"
      [disabled]="loading"
      (tapped)="giveReward()"
      [icon]="'redeem'"
    ></app-primary-button>
  </div>

  <div class="detail-container" id="left">
    <mat-tab-group
      disableRipple="true"
      [animationDuration]="0"
      (selectedTabChange)="tabChanged($event.index)"
      [(selectedIndex)]="selectedIndex"
    >
      <mat-tab label="Overview">
        <ng-template matTabContent>
          <div class="row">
            <div class="col-lg-6">
              <div style="margin-top: 20px">
                <div class="form-field-label-subtitle">Name</div>
                <div class="form-field-label-title">
                  {{ isUser(customer.user) ? customer.user.name : "" }}
                </div>
              </div>

              <div style="margin-top: 20px">
                <div class="form-field-label-subtitle">Email</div>
                <div class="form-field-label-title">
                  {{
                    isUser(customer.user)
                      ? customer.user.email ?? "Hidden"
                      : ""
                  }}<span
                    ><info-icon
                      *ngIf="isUser(customer.user) && !customer.user.email"
                      text="This customer has switched off email notifications"
                    ></info-icon
                  ></span>
                </div>
              </div>

              <div style="margin-top: 20px">
                <div class="form-field-label-subtitle">DOB</div>
                <div class="form-field-label-title">
                  {{
                    isUser(customer.user)
                      ? customer.user.dob
                        ? (customer.user.dob | date : constants.shortDate)
                        : "-"
                      : "-"
                  }}
                </div>
              </div>

              <div style="margin-top: 20px">
                <div class="form-field-label-subtitle">Customer since</div>
                <div class="form-field-label-title">
                  {{ customer.createdAt | date : constants.longDate }}
                </div>
              </div>

              <div style="margin-top: 20px">
                <div
                  class="form-field-label-subtitle"
                  style="display: flex; align-items: center"
                >
                  Notes<mat-icon class="note-icon" (click)="editNotes()"
                    >edit_note</mat-icon
                  >
                </div>
                <div class="form-field-label-title">
                  {{ customer.notes }}
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div
                *ngIf="getPreferences('communication')"
                style="margin-top: 20px"
              >
                <div class="form-field-label-subtitle">
                  Communication Preferences
                </div>
                <div class="form-field-label-title">
                  {{ getPreferences("communication") }}
                </div>
              </div>

              <div *ngIf="getPreferences('dietary')" style="margin-top: 20px">
                <div class="form-field-label-subtitle">Dietary Preferences</div>
                <div class="form-field-label-title">
                  {{ getPreferences("dietary") }}
                </div>
              </div>

              <div *ngIf="getPreferences('interests')" style="margin-top: 20px">
                <div class="form-field-label-subtitle">Interests</div>
                <div class="form-field-label-title">
                  {{ getPreferences("interests") }}
                </div>
              </div>

              <div *ngIf="getPreferences('shopping')" style="margin-top: 20px">
                <div class="form-field-label-subtitle">
                  Shopping Preferences
                </div>
                <div class="form-field-label-title">
                  {{ getPreferences("shopping") }}
                </div>
              </div>
            </div>
          </div>

          <!-- <div style="margin-top: 20px">
            <div class="form-field-label-subtitle">Birthday club</div>

            <div style="display: flex; align-items: center">
              <mat-icon
                class="status-icon"
                style="color: green; margin-right: 5px; font-size: 21px"
                *ngIf="customer?.visibility?.dob"
                >check_circle</mat-icon
              >
              <div class="form-field-label-title" style="margin-bottom: 0px">
                {{
                  isUser(customer.user)
                    ? (customer.user.dob | date : constants.shortDate)
                    : ""
                }}
              </div>
            </div>
          </div> -->
        </ng-template>
      </mat-tab>

      <mat-tab label="Stamps">
        <ng-template matTabContent>
          <div class="row">
            <div class="col-lg-6">
              <chart
                [type]="'stamp'"
                [chartType]="chartType"
                [customer]="customer._id"
                [days]="days"
                (optionTapped)="days = $event"
              ></chart>
            </div>
            <div class="col-lg-6">
              <hourly-chart
                [type]="'stamp'"
                [chartType]="chartType"
                [customer]="customer._id"
                [days]="days"
                (optionTapped)="days = $event"
              ></hourly-chart>
            </div>
          </div>

          <ngx-datatable
            #stampsTable
            class="material"
            [headerHeight]="45"
            [rows]="stampEvents"
            [scrollbarV]="false"
            [messages]="{ emptyMessage: 'No stamp events to display' }"
            [scrollbarH]="true"
            [footerHeight]="50"
            [columnMode]="'force'"
            [rowHeight]="48"
            [externalPaging]="true"
            [count]="stampLength"
            [offset]="stampIndex"
            (page)="stampPageEvent($event)"
            (scroll)="handleScroll()"
            [limit]="stampSize"
          >
            <ngx-datatable-column name="Stamp Date" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.createdAt | date : constants.longestDate }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Card" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a
                  [routerLink]="['/card', row?.card?.nanoid]"
                  (click)="$event.stopPropagation()"
                >
                  {{ row?.card?.title }}
                </a>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Source" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <img
                  *ngIf="constants.isShopify(row)"
                  src="../../../assets/shopify-logo.png"
                  width="20"
                  height="20"
                  style="margin-right: 5px"
                />
                <img
                  *ngIf="constants.isSquare(row)"
                  src="../../../assets/square-logo.png"
                  width="20"
                  height="20"
                  style="margin-right: 5px"
                />
                <img
                  *ngIf="constants.isSumUp(row)"
                  src="../../../assets/sumup.png"
                  width="20"
                  height="20"
                  style="margin-right: 5px"
                />
                <img
                  *ngIf="constants.isZapier(row)"
                  src="../../../assets/zapier-logo-small.png"
                  width="20"
                  height="20"
                  style="margin-right: 5px"
                />
                <img
                  *ngIf="constants.isStorekit(row)"
                  src="../../../assets/storekit.png"
                  width="20"
                  height="20"
                  style="margin-right: 5px"
                />
                <span
                  *ngIf="
                    row?.source?.user ||
                      (row?.source?.nfc && !row?.source?.nfc?.master);
                    else altSource
                  "
                >
                  <a
                    *ngIf="row?.source?.user"
                    [routerLink]="['/team-member', row.source?.user?.nanoid]"
                    (click)="$event.stopPropagation()"
                  >
                    {{ constants.sourceString(row) }}
                  </a>
                  <a
                    *ngIf="row?.source?.nfc"
                    [routerLink]="['/nfc-events', row.source?.nfc?.ref]"
                    (click)="$event.stopPropagation()"
                  >
                    {{ constants.sourceString(row) }}
                  </a>
                </span>

                <ng-template #altSource>
                  {{ constants.sourceString(row) }}
                </ng-template>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Stamp #" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.stampNumber }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Reward Stamp?" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <mat-icon
                  class="status-icon"
                  style="color: green"
                  *ngIf="row.hasReward"
                  >check_circle</mat-icon
                >
                <mat-icon
                  class="status-icon"
                  style="color: red"
                  *ngIf="!row.hasReward"
                  >cancel</mat-icon
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="stampLength > 0">
                  <span>{{ stampsRange }}</span>
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [page]="stampIndex + 1"
                  [size]="pageSize"
                  [count]="stampLength"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="stampsTable.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </ng-template>
      </mat-tab>

      <mat-tab label="Redemptions">
        <ng-template matTabContent>
          <div class="row">
            <div class="col-lg-6">
              <chart
                [type]="'redemption'"
                [chartType]="chartType"
                [customer]="customer._id"
                [days]="days"
                (optionTapped)="days = $event"
              ></chart>
            </div>
            <div class="col-lg-6">
              <hourly-chart
                [type]="'redemption'"
                [chartType]="chartType"
                [customer]="customer._id"
                [days]="days"
                (optionTapped)="days = $event"
              ></hourly-chart>
            </div>
          </div>

          <ngx-datatable
            #redemptionsTable
            class="material"
            [headerHeight]="45"
            [rows]="redemptions"
            [scrollbarV]="false"
            [messages]="{ emptyMessage: 'No redemptions to display' }"
            [scrollbarH]="true"
            [footerHeight]="50"
            [columnMode]="'force'"
            [rowHeight]="48"
            [externalPaging]="true"
            [count]="redemptionLength"
            [offset]="redemptionIndex"
            (page)="redemptionPageEvent($event)"
            (scroll)="handleScroll()"
            [limit]="redemptionSize"
          >
            <ngx-datatable-column name="Redemption Date" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.createdAt | date : constants.longestDate }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Reward" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a
                  [routerLink]="['/reward', row?.reward?.nanoid]"
                  (click)="$event.stopPropagation()"
                >
                  {{ row?.reward?.title }}
                </a>
              </ng-template>
            </ngx-datatable-column>

            <!-- <ngx-datatable-column name="Card/PN" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.card?.title ?? "Push Notification" }}
              </ng-template>
            </ngx-datatable-column> -->

            <ngx-datatable-column name="Source" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span
                  *ngIf="
                    row?.source?.user ||
                      (row?.source?.nfc && !row?.source?.nfc?.master);
                    else altSource
                  "
                >
                  <a
                    *ngIf="row?.source?.user"
                    [routerLink]="['/team-member', row.source?.user?.nanoid]"
                    (click)="$event.stopPropagation()"
                  >
                    {{ constants.sourceString(row) }}
                  </a>
                  <a
                    *ngIf="row?.source?.nfc"
                    [routerLink]="['/nfc-events', row.source?.nfc?.ref]"
                    (click)="$event.stopPropagation()"
                  >
                    {{ constants.sourceString(row) }}
                  </a>
                </span>

                <ng-template #altSource>
                  {{ constants.sourceString(row) }}
                </ng-template>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="redemptionLength > 0">
                  <span>{{ redemptionsRange }}</span>
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [page]="redemptionIndex + 1"
                  [size]="pageSize"
                  [count]="redemptionLength"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="redemptionsTable.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </ng-template>
      </mat-tab>

      <mat-tab label="Cards">
        <ng-template matTabContent>
          <ngx-datatable
            #cardsTable
            class="material"
            [headerHeight]="45"
            [rows]="userCards"
            [scrollbarV]="false"
            [messages]="{ emptyMessage: 'No stamp cards to display' }"
            [scrollbarH]="true"
            [footerHeight]="50"
            [columnMode]="'force'"
            [rowHeight]="48"
            [externalPaging]="true"
            [count]="cardLength"
            [offset]="cardIndex"
            (page)="cardPageEvent($event)"
            (scroll)="handleScroll()"
            [limit]="cardSize"
          >
            <ngx-datatable-column name="Date Taken" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.createdAt | date : constants.longDate }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Card" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div *ngIf="!(row?.card[0] ?? row?.card)?.title">
                  {{ (row?.card[0] ?? row?.card)?.title ?? "" }}
                </div>

                <a
                  *ngIf="(row?.card[0] ?? row?.card)?.title"
                  [routerLink]="['/card', (row?.card[0] ?? row?.card)?.nanoid]"
                  (click)="$event.stopPropagation()"
                >
                  {{ (row?.card[0] ?? row?.card)?.title }}
                </a>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="# Stamps" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div style="display: flex">
                  <mat-progress-spinner
                    class="progress"
                    [color]="color"
                    [mode]="mode"
                    [value]="
                      (row?.numberOfStamps /
                        (row?.card[0] ?? row?.card)?.numberOfStamps) *
                      100
                    "
                    [diameter]="20"
                  >
                  </mat-progress-spinner>
                  <span
                    >{{
                      row?.numberOfStamps >
                      (row?.card[0] ?? row?.card)?.numberOfStamps
                        ? (row?.card[0] ?? row?.card)?.numberOfStamps
                        : row?.numberOfStamps
                    }}/{{ (row?.card[0] ?? row?.card)?.numberOfStamps }}</span
                  >
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="# Resets" [resizeable]="false">
              <ng-template ngx-datatable-header-template>
                <div>
                  # Resets
                  <info-icon
                    text="The number of times this card has been re-used, a useful metric for environmental benefit and cost saving"
                  ></info-icon>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.resets === 0 ? "-" : row?.resets }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Actions" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <mat-icon
                  class="status-icon"
                  style="color: var(--app-timber)"
                  [mat-menu-trigger-for]="cardMenu"
                  [matMenuTriggerData]="{ userCard: row }"
                  >more_horiz</mat-icon
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="cardLength > 0">
                  <span>{{ cardsRange }}</span>
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [page]="cardIndex + 1"
                  [size]="pageSize"
                  [count]="cardLength"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="cardsTable.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </ng-template>
      </mat-tab>

      <mat-tab label="Rewards">
        <ng-template matTabContent>
          <div class="row">
            <div class="col-lg-6">
              <chart
                [type]="'reward'"
                [chartType]="chartType"
                [customer]="customer._id"
                [days]="days"
                (optionTapped)="days = $event"
              ></chart>
            </div>
            <div class="col-lg-6">
              <hourly-chart
                [type]="'reward'"
                [chartType]="chartType"
                [customer]="customer._id"
                [days]="days"
                (optionTapped)="days = $event"
              ></hourly-chart>
            </div>
          </div>

          <ngx-datatable
            #rewardsTable
            class="material"
            [headerHeight]="45"
            [rows]="userRewards"
            [scrollbarV]="false"
            [messages]="{ emptyMessage: 'No rewards to display' }"
            [scrollbarH]="true"
            [footerHeight]="50"
            [columnMode]="'force'"
            [rowHeight]="48"
            [externalPaging]="true"
            [count]="rewardLength"
            [offset]="rewardIndex"
            (page)="rewardPageEvent($event)"
            (scroll)="handleScroll()"
            [limit]="rewardSize"
          >
            <ngx-datatable-column name="Date Received" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.createdAt | date : constants.longDate }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Reward" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div *ngIf="!(row?.reward[0] ?? row?.reward)?.title">
                  {{ (row?.reward[0] ?? row?.reward)?.title ?? "" }}
                </div>

                <a
                  *ngIf="(row?.reward[0] ?? row?.reward)?.title"
                  [routerLink]="[
                    '/reward',
                    (row?.reward[0] ?? row?.reward)?.nanoid
                  ]"
                  (click)="$event.stopPropagation()"
                >
                  {{ (row?.reward[0] ?? row?.reward)?.title }}
                </a>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Source" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ constants.userRewardSource(row) }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Expires" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{
                  row?.expiry
                    ? (row?.expiry | date : constants.longDate)
                    : "Never"
                }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Status" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div
                  id="status-chip"
                  [ngClass]="constants.statusChipClass(row)"
                >
                  {{ constants.statusChipLabel(row) }}
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Actions" [resizeable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <mat-icon
                  *ngIf="!row.hasExpired && !row.hasRedeemed"
                  class="status-icon"
                  style="color: var(--app-timber)"
                  [mat-menu-trigger-for]="rewardMenu"
                  [matMenuTriggerData]="{ reward: row }"
                  >more_horiz</mat-icon
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rewardLength > 0">
                  <span>{{ rewardsRange }}</span>
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [page]="rewardIndex + 1"
                  [size]="pageSize"
                  [count]="rewardLength"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="rewardsTable.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<loader [loading]="loading"></loader>
<loader [loading]="saving || paging || nextLoading"></loader>

<mat-menu #cardMenu="matMenu" class="custom-menu">
  <ng-template matMenuContent let-userCard="userCard">
    <div *ngFor="let item of cardMenuOptions; let i = index">
      <div [matTooltip]="tooltip" [style.cursor]="'not-allowed'">
        <button
          [disabled]="!canManageCustomer"
          class="menu-list-item"
          style="border-radius: 5px"
          (click)="onClick(item.title, userCard)"
          mat-menu-item
        >
          <mat-icon
            fontSet="material-icons-outlined"
            style="
              font-size: 16px;
              vertical-align: middle;
              display: flex;
              align-items: center;
              margin-right: 5px;
            "
            >{{ item.icon }}</mat-icon
          >
          {{ item.title }}
        </button>
      </div>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #rewardMenu="matMenu" class="custom-menu">
  <ng-template matMenuContent let-reward="reward">
    <div
      *ngIf="!reward.hasRedeemed && !reward.hasExpired"
      [matTooltip]="tooltip"
      [style.cursor]="canManageCustomer ? 'pointer' : 'not-allowed'"
    >
      <button
        [disabled]="!canManageCustomer"
        class="menu-list-item"
        style="border-radius: 5px"
        (click)="onClick(rewardMenuOptions[0].title, reward._id)"
        mat-menu-item
      >
        <mat-icon
          fontSet="material-icons-outlined"
          style="
            font-size: 16px;
            vertical-align: middle;
            display: flex;
            align-items: center;
            margin-right: 5px;
          "
          >{{ rewardMenuOptions[0].icon }}</mat-icon
        >
        {{ rewardMenuOptions[0].title }}
      </button>
    </div>
    <div
      *ngIf="!reward.hasExpired && !reward.hasRedeemed"
      [matTooltip]="tooltip"
      [style.cursor]="canManageCustomer ? 'pointer' : 'not-allowed'"
    >
      <button
        [disabled]="!canManageCustomer"
        class="menu-list-item"
        style="border-radius: 5px"
        (click)="onClick(rewardMenuOptions[1].title, reward._id)"
        mat-menu-item
      >
        <mat-icon
          fontSet="material-icons-outlined"
          style="
            font-size: 16px;
            vertical-align: middle;
            display: flex;
            align-items: center;
            margin-right: 5px;
          "
          >{{ rewardMenuOptions[1].icon }}</mat-icon
        >
        {{ rewardMenuOptions[1].title }}
      </button>
    </div>
  </ng-template>
</mat-menu>
