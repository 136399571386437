<page-header
  [title]="(editMode ? 'Edit ' : 'New ') + constants.strings.location"
  [subtitle]="subtitle"
></page-header>

<form [formGroup]="form" *ngIf="!loading">
  <div
    [ngStyle]="{
      justifyContent:
        editMode && location.qrCode?.url ? 'space-between' : 'flex-end'
    }"
    style="display: flex; margin-bottom: 10px"
  >
    <app-primary-button
      *ngIf="editMode && location?.qrCode?.url"
      [title]="constants.strings.downloadQRCode"
      [disabled]="loading"
      [tooltip]="tooltip"
      (tapped)="openQRCode()"
      [icon]="'qr_code'"
    ></app-primary-button>

    <div style="display: flex">
      <app-primary-button
        [title]="constants.strings.cancel"
        border=" 1px solid var(--app-container)"
        bgColor="transparent"
        color="white"
        (tapped)="back()"
      ></app-primary-button>

      <div style="margin-left: 10px">
        <app-primary-button
          title="Save"
          border=" 1px solid var(--app-container)"
          [disabled]="loading || !canCreateLocation"
          [tooltip]="tooltip"
          (tapped)="save()"
        ></app-primary-button>
      </div>
    </div>
  </div>

  <div class="row" style="margin-bottom: 70px">
    <div class="col-lg-6">
      <div class="detail-container" id="left">
        <div class="form-field">
          <div class="form-field-label">
            Name (How your location will appear on the map)<span
              style="color: red"
              >*</span
            >
          </div>
          <input
            class="form-input"
            placeholder="e.g. Brew Haven - High St"
            autocomplete="off"
            [formControl]="nameFormControl"
            type="text"
            [ngStyle]="{
              border:
                nameFormControl.invalid && submitted
                  ? '1px solid red'
                  : '1px solid var(--app-light-grey)'
            }"
          />
        </div>
        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label">
            Address<span style="color: red">*</span>
          </div>
          <input
            class="form-input"
            [readOnly]="true"
            placeholder="e.g. 123 High St, London, E1 AAB"
            autocomplete="off"
            [formControl]="addressFormControl"
            type="text"
            (click)="openAddressDialog()"
            [ngStyle]="{
              border:
                addressFormControl.invalid && submitted
                  ? '1px solid red'
                  : '1px solid var(--app-light-grey)'
            }"
          />
        </div>
        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label">
            Description<span style="color: red">*</span>
          </div>
          <textarea
            class="form-input"
            autocomplete="off"
            [formControl]="descriptionFormControl"
            placeholder="e.g. Welcome to Brew Haven Coffee Co, where the aroma of freshly brewed coffee fills the air, and every cup tells a story."
            type="text"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            [maxlength]="200"
            [rows]="3"
            [ngStyle]="{
              border:
                descriptionFormControl.invalid && submitted
                  ? '1px solid red'
                  : '1px solid var(--app-light-grey)'
            }"
          ></textarea>
        </div>
        <div class="form-field" style="margin-top: 20px">
          <div class="form-field-label">Tags</div>
          <mat-form-field appearance="outline" class="example-chip-list">
            <mat-chip-list #chipList>
              <mat-chip
                *ngFor="let fruit of tags"
                [selectable]="false"
                [removable]="true"
                (removed)="remove(fruit)"
              >
                {{ fruit.name }}
                <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
              </mat-chip>
              <input
                [placeholder]="
                  tags.length
                    ? ''
                    : 'e.g. Coffee (start typing then press enter)'
                "
                class="form-input"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="add($event)"
              />
            </mat-chip-list>
          </mat-form-field>
        </div>

        <div class="row">
          <div class="col-lg-6" style="margin-top: 20px">
            <div class="form-field">
              <div class="form-field-label">WIFI Name<span></span></div>
              <input
                class="form-input"
                autocomplete="off"
                [formControl]="ssidFormControl"
                type="text"
              />
            </div>
          </div>
          <div class="col-lg-6" style="margin-top: 20px">
            <div class="form-field">
              <div class="form-field-label">WIFI Password</div>
              <input
                class="form-input"
                autocomplete="off"
                [formControl]="wifiFormControl"
                type="text"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6" style="margin-top: 20px">
            <div class="form-field">
              <div class="form-field-label">Email Address</div>
              <input
                class="form-input"
                autocomplete="off"
                [formControl]="emailFormControl"
                type="email"
              />
            </div>
          </div>

          <div class="col-lg-6" style="margin-top: 20px">
            <div class="form-field">
              <div class="form-field-label">Phone Number</div>

              <div style="display: flex; align-items: center">
                <div class="country-code-selector" [matMenuTriggerFor]="menu">
                  {{ this.selectedCountry?.emoji
                  }}<span style="margin-left: 5px">{{
                    this.selectedCountry?.dialCode ?? "+44"
                  }}</span>
                </div>

                <input
                  class="form-input"
                  autocomplete="off"
                  [formControl]="phoneFormControl"
                  type="text"
                  [maxlength]="15"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="margin-top: 20px">
          <div class="col-lg-6">
            <div class="form-field">
              <div class="form-field-label">Website</div>
              <input
                class="form-input"
                autocomplete="off"
                [formControl]="websiteFormControl"
                type="text"
              />
            </div>
          </div>
          <!-- <div class="col-lg-6">
            <div class="form-field" style="margin-top: 20px">
              <div class="form-field-label">WIFI Code</div>
              <input
                class="form-input"
                autocomplete="off"
                [formControl]="wifiFormControl"
                type="text"
              />
            </div>
          </div> -->
        </div>

        <div style="display: flex; align-items: center; margin-top: 20px">
          <div
            class="form-field-label"
            style="margin-bottom: 0px; margin-right: 5px"
          >
            Social Links
          </div>
          <mat-icon
            class="status-icon"
            style="color: var(--app-timber); cursor: pointer"
            (click)="openSocialDialog()"
            >add_circle</mat-icon
          >
        </div>
        <div
          class="social-container"
          *ngFor="let item of socials"
          style="
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div style="display: flex; align-items: center">
            <img
              [src]="
                '../assets/social/' + (item.type.name ?? item.type) + '.png'
              "
              style="margin-right: 10px"
            />
            <div
              class="form-field-label"
              style="
                margin-bottom: 0px !important;
                color: black;
                font-size: 14px;
                font-weight: normal;
              "
            >
              {{
                buildLink(item.handle, item.type.name ?? item.type).replace(
                  "https://",
                  ""
                )
              }}
            </div>
          </div>

          <div>
            <mat-icon
              style="
                color: var(--app-timber);
                cursor: pointer;
                margin-right: 5px;
              "
              (click)="
                openSocialDialog(item.handle, item.type.name ?? item.type)
              "
              >edit</mat-icon
            >
            <mat-icon
              style="color: red; cursor: pointer"
              (click)="removeSocial(item)"
              >cancel</mat-icon
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="detail-container" id="right">
        <div style="display: flex; justify-content: space-between">
          <div class="form-field-label">
            Opening Times<span style="color: red">*</span>
          </div>
          <div *ngIf="location.address?.country === 'United Kingdom'">
            <mat-slide-toggle
              [disabled]="false"
              [checked]="gmt"
              (change)="gmt = !gmt"
              ><span class="section-subtitle"> {{ gmt ? "GMT" : "BST" }}</span>
            </mat-slide-toggle>
          </div>
        </div>

        <div class="opening-times">
          <div
            *ngFor="let day of gmt ? openingTimes : altOpeningTimes"
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
              height: 40.95px;
            "
          >
            <div style="display: flex">
              <mat-checkbox
                class="checkbox"
                style="margin-right: 10px"
                [checked]="day.active"
                (change)="toggleDay(day, $event.checked)"
              >
              </mat-checkbox>
              <p class="day-label">
                {{ (isHandset$ | async) ? day.label : day.day }}
              </p>
            </div>

            <div *ngIf="!day.active">
              <p class="day-label">Closed</p>
            </div>

            <div *ngIf="day.active">
              <input
                style="margin-right: 10px"
                class="small-form-input"
                autocomplete="off"
                [formControl]="day.fromControl"
                type="time"
                (change)="timeChanged()"
                [ngStyle]="{
                  border:
                    day.fromControl.invalid && submitted
                      ? '1px solid red'
                      : '1px solid var(--app-light-grey)'
                }"
              />
              <input
                class="small-form-input"
                autocomplete="off"
                [formControl]="day.toControl"
                [value]="day.toControl.value"
                type="time"
                (change)="timeChanged()"
                [ngStyle]="{
                  border:
                    day.toControl.invalid && submitted
                      ? '1px solid red'
                      : '1px solid var(--app-light-grey)'
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="detail-container" id="right" style="margin-top: 20px">
        <div class="form-field-label" style="margin-bottom: 20px">
          Cover Image
        </div>
        <file-upload
          (fileSelected)="selectedCover = $event"
          [readerResult]="initialCoverUrl ?? ''"
          [rounded]="true"
        ></file-upload>

        <div style="margin-top: 20px" *ngIf="showCustomBrandingOption">
          <mat-radio-group
            [value]="selectedLogoOption"
            aria-label="Select an option"
            (change)="selectedLogoOption = $event.value"
          >
            <div class="form-field-label" style="text-align: left">
              Display logo
            </div>
            <div
              *ngFor="let option of options"
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-left: 20px;
                margin-bottom: 10px;
              "
            >
              <span>{{ option.label }}</span>
              <mat-radio-button [value]="option.value"></mat-radio-button>
            </div>

            <file-upload
              *ngIf="selectedLogoOption == constants.locationLogo.location"
              (fileSelected)="selectedLogo = $event"
              [readerResult]="initialLogoUrl ?? ''"
              [rounded]="true"
              [aspectRatio]="1 / 1"
            ></file-upload>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
</form>

<loader [loading]="loading || saving"></loader>

<mat-menu #menu="matMenu" class="custom-menu">
  <input
    (click)="$event.stopPropagation()"
    class="search-input"
    autocomplete="off"
    [formControl]="searchFormControl"
    type="text"
    placeholder="Search..."
    (ngModelChange)="onTypeSearch()"
  />
  <ng-template matMenuContent>
    <div
      *ngFor="let item of searchActive ? filteredCountryCodes : countryCodes"
    >
      <button
        [disabled]="false"
        class="menu-list-item"
        style="border-radius: 5px; cursor: pointer"
        [ngStyle]="{
          'font-weight': item.code === selectedCountry?.code ? '500' : 'normal',
          'background-color':
            item.code === selectedCountry?.code ? '#eeeeee' : 'transparent'
        }"
        (click)="onSelectCountryCode(item)"
        mat-menu-item
      >
        {{ item.emoji }}
        {{ item.name }} ({{ item.dialCode }})
      </button>
    </div>
  </ng-template>
</mat-menu>
