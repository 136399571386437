import { StampCard, User, Merchant } from './index';

export interface UserCard {
  _id?: string;
  nanoid?: string;
  card?: string | StampCard;
  user?: string | User;
  merchant?: string | Merchant;
  numberOfStamps?: number;
}
