<mat-card
  class="card"
  [ngClass]="{
    'card-popular': plan.mostPopular === true
  }"
>
  <div class="popular-container" *ngIf="plan.mostPopular">Most popular</div>

  <mat-card-title> {{ emoji }} {{ plan.name }} </mat-card-title>
  <mat-card-subtitle
    ><span class="basis">{{ basisString }}</span></mat-card-subtitle
  >

  <p class="price" style="padding-top: 20px; padding-bottom: 20px">
    <span
      *ngIf="basis === 'year'"
      style="
        text-decoration: line-through;
        color: var(--app-text-grey);
        font-weight: normal;
        font-size: 20px;
      "
      >{{
        annualDisplayPrice / 100
          | currency : currency.toUpperCase() : "symbol" : "1.0-0"
      }}</span
    >
    {{
      displayPrice / 100
        | currency : currency.toUpperCase() : "symbol" : "1.0-0"
    }}
    <span style="font-size: 16px; font-weight: normal">/mo</span>
  </p>

  <ul>
    <li *ngFor="let feature of plan?.features">
      <mat-icon fontSet="material-icons-outlined" class="icon"
        >check_circle</mat-icon
      >
      <span>{{ feature?.name }}</span>
    </li>
  </ul>

  <mat-card-actions>
    <button
      (click)="buttonTapped.emit(price)"
      mat-flat-button
      [disabled]="loading || isActivePlan() || !enabled || hasPlan"
      style="width: 100%"
      class="primary-button"
    >
      <span *ngIf="isActivePlan()">Current Plan</span>
      <span
        *ngIf="!isActivePlan()"
        [matTooltip]="
          hasPlan ? 'Please contact support to change your plan' : ''
        "
        >{{ upgradeString + " to " + plan.name }}</span
      >
    </button>
  </mat-card-actions>
  <p style="color: grey; font-style: italic">
    * extra location: +{{ currencySymbol + locationPrice }}/mo​
  </p>
</mat-card>
