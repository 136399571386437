import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/index';
import { Constants } from '../app.constants';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authService.getUserProperty('token');

    if (user) {
      return true;
    }

    this.authService.logout();

    let queryParams: any = {};

    if (state.url !== '/') {
      queryParams['redirect'] = state.url.replace('/', '');
    }

    this.router.navigate(['/' + Constants.routes.login], {
      queryParams: queryParams,
    });

    return false;
  }
}
