import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { Constants } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';

import { InsightsService } from '../../../services/index';

@Component({
  selector: 'hourly-chart',
  templateUrl: './hourly-chart.component.html',
  styleUrls: ['./hourly-chart.component.css'],
})
export class HourlyChartComponent {
  labels: string[] = [];
  loading = false;
  @Input() type = 'stamp';
  @Input() days = '30';
  @Input() chartType = 'line';
  @Input() customer: string | undefined;
  @Output() optionTapped = new EventEmitter<string>();

  public barChartLegend = false;
  public barChartPlugins = [];

  barChartData: ChartData | undefined;

  menuOptions: any[] = [
    { title: 'Last 7 days', value: '7' },
    { title: 'Last 30 days', value: '30' },
    { title: 'Last 3 months', value: '90' },
    { title: 'Last 6 months', value: '180' },
    { title: 'Last 12 months', value: '360' },
  ];

  title: string = '';

  constructor(
    private insightService: InsightsService,
    private constants: Constants,
    private mixpanel: AnalyticsAbstract
  ) {}

  public barChartOptions: ChartConfiguration['options'] = {};

  setTitle(): void {
    this.title = `Hourly Distribution of ${
      this.type[0].toUpperCase() + this.type.slice(1)
    }s`;
  }

  tapped(item: any): void {
    this.days = item.value;

    this.mixpanel.track(Constants.analytics_keys.toggleGraphDays, {
      Days: this.days,
    });

    this.optionTapped.emit(this.days);
  }

  values(valueType: string, events: any[]): number[] {
    const val: number[] = [];

    if (events?.length) {
      for (const label of this.labels) {
        const [day, month] = label.split('/').map(Number);
        const year = new Date().getFullYear();

        const obj = events.find(
          (el: any) => el.day === day && el.month === month && el.year === year
        );

        val.push(obj ? parseFloat(obj[valueType].toFixed(0)) : 0);
      }

      return val;
    } else {
      return [];
    }
  }

  onChanged(): void {
    this.mixpanel.track(Constants.analytics_keys.toggleGraphDays, {
      Days: this.days,
    });

    this.optionTapped.emit(this.days);
  }

  ngOnInit(): void {
    this.setTitle();

    this.barChartOptions = {
      responsive: true,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          ticks: {
            stepSize: 10,
            callback: function (value: number | string) {
              return value + '%';
            },
          },
        },
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
      plugins: {
        title: {
          display: true,
          text: this.title,
          font: {
            size: 14,
            weight: '500',
          },
          padding: {
            top: 10,
            bottom: 10,
          },
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          callbacks: {
            title: () => '', // Return an empty string to hide the default title
            label: (context: { dataset: any; parsed: any }) => {
              const value = context.parsed.y;
              const labelIndex = context.parsed.x; // Get the index of the label
              const hourLabel = this.labels[labelIndex]; // Get the label from the labels array
              return `${hourLabel}:00 - ${value.toFixed(0)}%`; // Append ':00' to the label and display the value
            },
          },
        },
        legend: {
          labels: {
            usePointStyle: true,
            boxWidth: 20,
          },
        },
      },
    };
  }

  ngOnChanges(): void {
    this.getLabels();
    this.getBarChartData();

    this.setTitle();
  }

  getLabels(): void {
    this.labels = Array.from({ length: 17 }, (_, i) =>
      (i + 6).toString().padStart(2, '0')
    ); // 06, 07, 08, ..., 22
  }

  getBarChartData(): void {
    this.loading = true;

    let observable$;

    if (this.type === 'stamp') {
      observable$ = this.insightService.getHourlyDistribution(
        +this.days,
        'stamp',
        this.customer
      );
    } else if (this.type === 'redemption') {
      observable$ = this.insightService.getHourlyDistribution(
        +this.days,
        'redemption',
        this.customer
      );
    } else if (this.type === 'card') {
      observable$ = this.insightService.getHourlyDistribution(
        +this.days,
        'card',
        this.customer
      );
    } else {
      observable$ = this.insightService.getHourlyDistribution(
        +this.days,
        'reward',
        this.customer
      );
    }

    this.processResponse(observable$);
  }

  processResponse(observable$?: Observable<any>): void {
    observable$
      ?.subscribe({
        next: (res: any) => {
          if (res) {
            this.createChartData(res);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  createChartData(data: any): void {
    // Initialize an array with 0% for the hours from 6 AM to 10 PM
    const hourlyData = Array(17).fill(0);

    // Populate the hourlyData array with values from the API response
    data.forEach((el: any) => {
      const utcHour = el.hour;
      const localHour = this.convertUTCToLocalHour(utcHour);

      // Only include data for hours from 06:00 to 22:00
      if (localHour >= 6 && localHour <= 22) {
        hourlyData[localHour - 6] = parseFloat(el.value.toFixed(2));
      }
    });

    const color = '#f1a638';

    this.barChartData = {
      labels: this.labels,
      datasets: [
        {
          data: hourlyData,
          label: this.labelType,
          pointBackgroundColor: 'transparent',
          pointBorderWidth: 0,
          pointBorderColor: color,
          hoverBorderColor: color,
          borderColor: color,
          backgroundColor: this.convertHexToRGBA(color),
          hoverBackgroundColor: this.convertHexToRGBA(color),
          borderRadius: 10,
          borderWidth: 2,
          fill: 'origin',
          tension: 0.4,
        },
      ],
    };
  }

  convertUTCToLocalHour(utcHour: number): number {
    // Create a date object with the current date and the given UTC hour
    const date = new Date();
    date.setUTCHours(utcHour, 0, 0, 0);

    // Return the local hour extracted from the date object
    return date.getHours();
  }

  convertHexToRGBA(hex: string): string {
    if (hex.startsWith('#') === false) {
      hex = '#' + hex;
    }

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, 0.5)`;
  }

  get labelType(): string {
    if (this.type === 'card') {
      return 'Cards';
    }
    if (this.type === 'stamp') {
      return 'Stamps';
    }
    if (this.type === 'reward') {
      return 'Rewards';
    }
    return 'Redemptions';
  }
}
