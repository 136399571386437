<div
  class="box-container"
  [ngStyle]="{
    'background-color': bgColor ?? '#f7ecde'
  }"
>
  <div style="display: flex; justify-content: space-between">
    <div class="icon-title" *ngIf="title">
      <mat-icon class="info-icon">info</mat-icon>
      <p class="title">{{ title }}</p>
    </div>
    <mat-icon
      *ngIf="canDismiss"
      (click)="closeTapped.emit()"
      class="icon"
      style="color: var(--app-timber); font-size: 18px; cursor: pointer"
      >close</mat-icon
    >
  </div>

  <p *ngIf="!list" [innerHTML]="body"></p>

  <ul class="subtitle" *ngIf="list">
    <li *ngFor="let l of list">{{ l }}</li>
  </ul>
</div>
