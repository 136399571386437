import { Merchant, Reward, Location } from './index';

export interface User {
  _id?: string;
  firstName?: string;
  lastName?: string;
  dob?: Date;
  name?: string;
  nanoid?: string;
  userType?: string;
  email?: string;
  rewards?: string[] | Reward[];
  userName?: string;
  verified?: boolean;
  active?: boolean;
  isOwner?: boolean;
  createdAt?: Date;
  merchant?: string | Merchant;
  locations?: string[] | Location[];
  canViewCustomers?: boolean;
  canViewDashboard?: boolean;
  preferences?: any;
  reports?: {
    day: boolean;
    week: boolean;
    month: boolean;
  };
}
