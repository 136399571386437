<dialog-header
  [title]="data.edit?.handle ? 'Edit Social Account' : 'Add Social Account'"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <form [formGroup]="form" style="margin-top: 20px">
    <single-select
      label="Social Account"
      [formCtrl]="typeFormControl"
      [options]="options"
      property="name"
      [showIcon]="true"
    ></single-select>

    <div class="form-field" style="margin-top: 20px">
      <div class="form-field-label">Handle</div>
      <input
        class="form-input"
        autocomplete="off"
        [formControl]="handleFormControl"
        type="text"
        placeholder="e.g. brewhavencoffeeco"
      />
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    style="margin-right: 10px"
    [title]="'Cancel'"
    [disabled]="false"
    (tapped)="matDialogRef.close()"
  ></app-secondary-button>

  <app-primary-button
    [title]="'Save'"
    [disabled]="false"
    (tapped)="primaryTapped()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>
