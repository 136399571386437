<page-header title="Customers" [subtitle]="subtitle"></page-header>

<div
  style="display: flex; justify-content: space-between; margin-bottom: 10px"
  *ngIf="!loading"
>
  <div style="display: flex; align-items: center; justify-content: center">
    <app-search-input
      (onSubmit)="searchCustomers()"
      (onChanged)="searchTerm = $event"
      (onCancel)="cancel()"
    ></app-search-input>
  </div>
</div>

<div *ngIf="!loading">
  <ngx-datatable
    #myTable
    class="material"
    [headerHeight]="45"
    [rows]="customers"
    [scrollbarV]="false"
    [scrollbarH]="true"
    [footerHeight]="50"
    [columnMode]="'force'"
    [rowHeight]="48"
    [externalPaging]="true"
    [messages]="{ emptyMessage: emptyString }"
    [count]="length"
    [offset]="pageIndex"
    (page)="pageEvent($event)"
    [limit]="pageSize"
    (scroll)="handleScroll()"
    (sort)="onSort($event)"
    (activate)="rowTappedNGX($event)"
  >
    <ngx-datatable-column name="Customer since" [resizeable]="false" prop="_id">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.createdAt | date : constants.longDate }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Name" [resizeable]="true" prop="user.name">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.user?.name }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Email" [resizeable]="true" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.user?.email ?? "Hidden" }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="DOB" [resizeable]="false" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{
          row?.user?.dob ? (row?.user?.dob | date : constants.shortDate) : "-"
        }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Gender" [resizeable]="false" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{
          row?.user?.preferences?.gender ? row?.user?.preferences?.gender : "-"
        }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Stamps" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.stamps }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Redemptions" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.redemptions }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Cards" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.cards }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Rewards" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.rewards }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-offset="offset"
        let-isVisible="isVisible"
      >
        <div class="page-count" *ngIf="length > 0">
          <span>{{ customersRange }}</span>
        </div>
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="pageIndex + 1"
          [size]="pageSize"
          [count]="length"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

<loader [loading]="loading || exporting || searching"></loader>
