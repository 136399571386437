import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GeocodingService {
  private apiKey = 'AIzaSyBjcqeZzyQ-tT7t2SMNZFaYTUpCMn05IlA';

  constructor(private http: HttpClient) {}

  getCoordinates(postcode: string, countryCode: string): Observable<any> {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${postcode}&components=country:${countryCode}&key=${this.apiKey}`;
    return this.http.get(apiUrl);
  }
}
