import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';

import { RewardService } from '../../../services/index';
import { Reward } from '../../../models/index';

@Component({
  selector: 'reward-dialog',
  templateUrl: './reward-dialog.component.html',
  styleUrls: ['./reward-dialog.component.css'],
})
export class RewardDialogComponent implements OnInit {
  form!: FormGroup;
  rewardFormControl!: FormControl;
  numberFormControl!: FormControl;

  rewards: Reward[] = [];
  loading = false;
  error = false;

  pageIndex = 0;
  pageSize = 10;
  length = 0;

  constructor(
    public matDialogRef: MatDialogRef<RewardDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rewardService: RewardService,
    private constants: Constants
  ) {}

  ngOnInit(): void {
    this.rewardFormControl = new FormControl({ value: '', disabled: false }, [
      Validators.required,
    ]);
    this.numberFormControl = new FormControl({ value: '', disabled: false });

    if (this.data?.stampNumbers) {
      this.numberFormControl.setValidators([Validators.required]);
    }

    this.form = this.fb.group({
      reward: this.rewardFormControl,
      number: this.numberFormControl,
    });

    this.getRewards();
  }

  getRewards(): void {
    this.loading = true;
    this.rewardService
      .getRewards(1000, 0)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.length = res.totalDocs;
            this.rewards = res.docs;
            this.rewards?.sort((a, b) => a.title!.localeCompare(b.title!));
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  primaryTapped() {
    if (this.form?.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.matDialogRef.close({
      reward: this.rewardFormControl.value,
      stamp: this.numberFormControl.value,
    });
  }

  hasRewards(): void {
    if (!this.rewards.length) {
      this.constants.snack('Please add a reward first');
    }
  }
}
