<dialog-header
  [title]="data?.title ?? 'Confirm'"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div mat-dialog-content class="mat-typography" style="margin-top: 20px">
  <div class="form-field" style="display: flex; justify-content: space-between">
    <div
      class="form-field-label"
      [ngStyle]="{
        color: !revertToggle ? 'black' : 'grey'
      }"
    >
      Do not revert
    </div>
    <mat-slide-toggle
      [disabled]="false"
      [checked]="!revertToggle"
      (change)="revertToggle = !revertToggle"
    >
    </mat-slide-toggle>
  </div>

  <div
    class="form-field"
    style="margin-top: 20px; display: flex; justify-content: space-between"
  >
    <div
      class="form-field-label"
      style="padding-right: 10px"
      [ngStyle]="{
        color: revertToggle ? 'black' : 'grey'
      }"
    >
      {{
        "Revert back to " +
          data.originalStamps +
          ((data.originalStamps === 1 ? " stamp" : " stamps") +
            " after a period of time")
      }}
    </div>
    <mat-slide-toggle
      [disabled]="false"
      [checked]="revertToggle"
      (change)="revertToggle = !revertToggle"
    >
    </mat-slide-toggle>
  </div>
</div>

<div
  class="form-field"
  style="margin-top: 10px; padding-left: 10px"
  *ngIf="revertToggle"
>
  <div class="form-field-label">Date<span style="color: red">*</span></div>
  <input
    id="datetime-local-field"
    class="form-input"
    autocomplete="off"
    [formControl]="dateFormControl"
    type="date"
    [min]="minDate"
  />

  <div style="margin-top: 20px">
    <single-select
      label="Time"
      [formCtrl]="timeFormControl"
      [options]="times"
      [showIcon]="false"
    ></single-select>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button
    [disabled]="false"
    (click)="buttonOne()"
    mat-flat-button
    class="secondary-button"
    style="margin-right: 10px"
  >
    <span>{{ data.buttonOne }}</span>
  </button>
  <button
    [disabled]="false"
    (click)="buttonTwo()"
    mat-flat-button
    class="primary-button"
  >
    <span>{{ data.buttonTwo }}</span>
  </button>
</div>
