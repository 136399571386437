<dialog-header
  [title]="data?.title ?? 'Confirm'"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div mat-dialog-content class="mat-typography" style="margin-top: 20px">
  <p class="dialog-body" [innerHTML]="data.body | linkify"></p>
</div>

<div mat-dialog-actions align="end">
  <button
    [disabled]="false"
    (click)="buttonOne()"
    mat-flat-button
    class="secondary-button"
    style="margin-right: 10px"
  >
    <span>{{ data.buttonOne }}</span>
  </button>
  <button
    [disabled]="false"
    (click)="buttonTwo()"
    mat-flat-button
    class="primary-button"
  >
    <span>{{ data.buttonTwo }}</span>
  </button>
</div>
