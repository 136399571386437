<dialog-header
  [title]="data.title"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <form [formGroup]="form" style="margin-top: 20px">
    <div class="form-field" style="margin-top: 20px">
      <div class="form-field-label">
        {{ data.label }}<span style="color: red">*</span>
      </div>
      <textarea
        class="form-input"
        [formControl]="textFormControl"
        type="text"
        [maxlength]="1000"
        (change)="changed()"
      ></textarea>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button
    [disabled]="false"
    (click)="matDialogRef.close()"
    mat-flat-button
    class="secondary-button"
    style="margin-right: 10px"
  >
    <span>Cancel</span>
  </button>
  <button
    [disabled]="false"
    (click)="primaryTapped()"
    mat-flat-button
    class="primary-button"
  >
    <span>Save</span>
  </button>
</div>
