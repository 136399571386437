<page-header
  [title]="constants.strings.myTeam"
  [subtitle]="subtitle"
></page-header>

<div style="display: flex; justify-content: flex-end; margin-bottom: 10px">
  <app-primary-button
    style="margin-right: 10px"
    [title]="constants.strings.remyConnect"
    [disabled]="loading"
    [tooltip]="tooltip"
    (tapped)="remyConnect()"
    [icon]="'download'"
  ></app-primary-button>
  <app-primary-button
    [title]="constants.strings.newTeamMember"
    [disabled]="loading"
    [tooltip]="tooltip"
    (tapped)="newTeamMember()"
    [icon]="'add'"
  ></app-primary-button>
</div>

<div *ngIf="!teamLoading">
  <ngx-datatable
    #myTable
    class="material"
    [headerHeight]="45"
    [rows]="teamMembers"
    [scrollbarV]="false"
    [messages]="{ emptyMessage: emptyString }"
    [scrollbarH]="true"
    [footerHeight]="50"
    [columnMode]="'force'"
    [rowHeight]="48"
    [externalPaging]="true"
    [count]="length"
    [offset]="pageIndex"
    (page)="pageEvent($event)"
    (scroll)="handleScroll()"
    [limit]="pageSize"
    (activate)="rowTappedNGX($event)"
  >
    <ngx-datatable-column name="Created" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.createdAt | date : constants.longDate }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Name" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.name }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Email" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.email }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Role" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ role(row) }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Locations"
      [resizeable]="false"
      *ngIf="locationCount > 1"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{
          row?.userType === "merchant"
            ? "All locations"
            : row.locations?.length === 1
            ? row.locations[0]?.name
            : (row?.locations?.length || 0
              | pluralize : "location" : "locations")
        }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Status" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div id="status-chip" [ngClass]="constants.statusChipClass(row)">
          {{ row?.active ? "Active" : "Inactive" }}
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Verified" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <mat-icon class="status-icon" style="color: green" *ngIf="row.verified"
          >check_circle</mat-icon
        >
        <mat-icon class="status-icon" style="color: red" *ngIf="!row.verified"
          >cancel</mat-icon
        >
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Reports" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div style="display: flex">
          <div
            *ngIf="row.reports?.day"
            style="padding-right: 4px"
            [matTooltip]="'Daily Reports'"
          >
            <div class="day-circle">D</div>
            <mat-icon class="status-icon" *ngIf="!row.reports?.day"
              >cancel</mat-icon
            >
          </div>
          <div
            *ngIf="row.reports?.week"
            style="padding-right: 4px"
            [matTooltip]="'Weekly Reports'"
          >
            <div class="day-circle">W</div>
            <mat-icon class="status-icon" *ngIf="!row.reports?.week"
              >cancel</mat-icon
            >
          </div>
          <div *ngIf="row.reports?.month" [matTooltip]="'Monthly Reports'">
            <div class="day-circle">M</div>
            <mat-icon class="status-icon" *ngIf="!row.reports?.month"
              >cancel</mat-icon
            >
          </div>
          <div
            *ngIf="
              !row.reports?.month && !row.reports?.week && !row.reports?.day
            "
          >
            -
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-offset="offset"
        let-isVisible="isVisible"
      >
        <div class="page-count" *ngIf="length > 0">
          <span>{{ teamsRange }}</span>
        </div>
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="pageIndex + 1"
          [size]="pageSize"
          [count]="length"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

<loader [loading]="loading"></loader>
