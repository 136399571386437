import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { AuthService } from '../../../services/index';
import { Shapes } from '../../../models/index';

import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Observable, map } from 'rxjs';
import { TwoOptionAlertComponent } from 'src/app/shared/components/two-option-alert/two-option-alert.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'card-preview',
  templateUrl: './card-preview.component.html',
  styleUrls: ['./card-preview.component.css'],
})
export class CardPreviewComponent implements OnInit {
  @Input() cardGradient?: {
    from: string;
    to: string;
    angle: number;
    value: number;
  };
  @Input() stampImage?: any;
  @Input() rewardImage?: any;
  @Input() textColour: string = '';
  @Input() cardColour: string = '';
  @Input() stampColour: string = '';
  @Input() stampFillEmptyColour?: string = '';
  @Input() stampOutlineColour: string = '';
  @Input() iconColor: string = '';
  @Input() rewardIcon?: any;
  @Input() rewardIcons?: any[];
  @Input() stampIcon?: any;
  @Input() stamps: any[] = [];
  @Input() rewards: any[] = [];
  @Input() title?: string;
  @Input() description?: string;
  @Input() iconOpacity: number = 0.1;
  @Input() showLogo: boolean = true;
  @Input() showStamps: boolean = true;
  @Input() shape: Shapes = Shapes.square;
  @Input() merchantLogo?: string | undefined;
  timestamp = new Date().getTime();

  constructor(
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    public fb: FormBuilder,
    public matDialogRef: MatDialogRef<CardPreviewComponent>,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result: BreakpointState) => {
        return result.matches;
      })
    );

  getIcon(index: number): string {
    if (this.isReward(index)) {
      if (this.rewardIcons) {
        const customRewardIcon = this.rewardIcons?.find(
          (el) => el.stamp === index + 1
        );

        if (customRewardIcon) {
          return customRewardIcon.web;
        } else {
          return this.rewardIcon?.web;
        }
      } else {
        return this.rewardIcon?.web;
      }
    } else {
      return this.stampIcon?.web;
    }
  }

  getIsFilled(index: number): boolean {
    if (this.isReward(index)) {
      if (this.rewardIcons) {
        const customRewardIcon = this.rewardIcons?.find(
          (el) => el.stamp === index + 1
        );

        if (customRewardIcon) {
          return customRewardIcon?.filled;
        } else {
          return this.rewardIcon?.filled;
        }
      } else {
        return this.rewardIcon?.filled;
      }
    } else {
      return this.stampIcon?.filled;
    }
  }

  isReward(index: number): boolean {
    for (const reward of this.rewards) {
      const i = index + 1;
      if (reward?.stamp?.number === i || reward?.stamp === i) {
        return true;
      }
    }
    return false;
  }

  getReward(index: number) {
    for (const reward of this.rewards) {
      const i = index + 1;
      if (reward?.stamp?.number === i || reward?.stamp === i) {
        return reward;
      }
    }
    return null;
  }

  borderRadius(inner = false): string {
    if (this.shape === Shapes.circle) {
      return '50%';
    }
    if (this.shape === Shapes.rounded) {
      if (inner) {
        return '11%';
      } else {
        return '15%';
      }
    }

    return '0%';
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.cardGradient) {
    }
  }

  stampClicked(i: number): void {
    if (this.isReward(i)) {
      let reward = this.getReward(i);

      if (reward) {
        this.dialog.open(TwoOptionAlertComponent, {
          data: {
            title: reward?.reward?.title,
            body: reward?.reward?.description,
            buttonTwo: 'Close',
          },
          scrollStrategy: new NoopScrollStrategy(),
          autoFocus: false,
          width: '350px',
          disableClose: true,
          panelClass: 'custom-dialog',
        });
      }
    }
  }

  customRewardImage(index: number) {
    const customReward = this.rewardIcons?.find((el) => el.stamp === index + 1);

    if (customReward && customReward?.web) {
      return null;
    }

    if (customReward && customReward?.image) {
      return customReward?.image?.content ?? customReward?.image?.url;
    }
    return this.rewardImage;
  }
}
