import { NgModule } from '@angular/core';
import { PageHeaderComponent } from './components/page-header/page-header.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SingleSelectComponent } from './components/single-select/single-select.component';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MultipleSelectComponent } from './components/multiple-select/multiple-select.component';

import { LottieModule } from 'ngx-lottie';
import { LoaderComponent } from './components/loader/loader.component';
import { InfoIconComponent } from './components/info-icon/info-icon.component';
import { TwoOptionAlertComponent } from './components/two-option-alert/two-option-alert.component';
import { LinkifyPipe } from './linkify.pipe';
import { TextAreaDialogComponent } from './components/text-area-dialog/text-area-dialog.component';
import { SingleSelectDialogComponent } from './components/single-select-dialog/single-select-dialog.component';
import { AppMaterialModule } from '../app-material.module';
import { ChecklistComponent } from './checklist/checklist.component';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { LinkTextComponent } from './components/link-text/link-text.component';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [
    PageHeaderComponent,
    SingleSelectComponent,
    MultipleSelectComponent,
    LoaderComponent,
    InfoIconComponent,
    TwoOptionAlertComponent,
    LinkifyPipe,
    TextAreaDialogComponent,
    SingleSelectComponent,
    SingleSelectDialogComponent,
    ChecklistComponent,
    DialogHeaderComponent,
    LinkTextComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    LottieModule,
    AppMaterialModule,
  ],
  exports: [
    PageHeaderComponent,
    SingleSelectComponent,
    MultipleSelectComponent,
    LoaderComponent,
    InfoIconComponent,
    TwoOptionAlertComponent,
    LinkifyPipe,
    TextAreaDialogComponent,
    SingleSelectComponent,
    SingleSelectDialogComponent,
    ChecklistComponent,
    DialogHeaderComponent,
    LinkTextComponent,
  ],
  providers: [DatePipe, LinkifyPipe],
})
export class SharedModule {}
