import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import { DateRangeDialogComponent } from 'src/app/shared/components/date-range-dialog/date-range-dialog.component';
import { PlanSuccessDialogComponent } from 'src/app/shared/components/plan-success-dialog/plan-success-dialog.component';

import {
  Redemption,
  StampEvent,
  UserCard,
  UserReward,
} from '../../models/index';

import {
  AuthService,
  CardService,
  ExcelService,
  GoogleAnalyticsService,
  InsightsService,
  MerchantService,
  RewardService,
  StorageService,
} from '../../services/index';

interface Insights {
  stampEvents?: string;
  customers?: string;
  cards?: string;
  rewards?: string;
  redemptions?: string;
}

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent {
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';

  options: { value: string; icon: string; label: string }[] = [
    { value: 'line', icon: 'show_chart', label: 'Line' },
    { value: 'bar', icon: 'bar_chart', label: 'Bar' },
  ];

  exporting = false;
  title = '';
  subtitle: string = '';
  @ViewChild('stampsTable') stampsTable: any;
  @ViewChild('cardsTable') cardsTable: any;
  @ViewChild('rewardsTable') rewardsTable: any;
  @ViewChild('redemptionsTable') redemptionsTable: any;

  stampPageIndex = 0;
  stampPageSize = 20;
  stampLength = 0;

  redemptionPageIndex = 0;
  redemptionPageSize = 20;
  redemptionLength = 0;

  cardsPageIndex = 0;
  cardsPageSize = 20;
  cardsLength = 0;

  rewardsPageIndex = 0;
  rewardsPageSize = 20;
  rewardsLength = 0;

  recentStamps: StampEvent[] = [];
  redemptions: Redemption[] = [];
  cards: UserCard[] = [];
  rewards: UserReward[] = [];

  insights: Insights = {};

  eventsLoading = false;
  redemptionsLoading = false;
  cardsLoading = false;
  rewardsLoading = false;
  insightsLoading = false;
  hourlyLoading = false;

  error = false;

  tabs = ['stamps', 'redemptions', 'cards', 'rewards'];
  selectedTab = 'stamps';
  selectedIndex = 0;

  scrollTimeout: any;
  suppressPaging: boolean = false;

  stampsRange = '';
  redemptionsRange = '';
  cardsRange = '';
  rewardsRange = '';
  days = '30';
  customerId?: string;
  menuOptions: any[] = [
    {
      title: this.constants.strings.exportAllData,
      icon: 'mark_chat_unread',
    },
    {
      title: this.constants.strings.lapsedCustomerReport,
      icon: 'mark_chat_unread',
    },
  ];

  dateOptions: any[] = [
    { title: 'Last 7 days', value: '7' },
    { title: 'Last 30 days', value: '30' },
    { title: 'Last 3 months', value: '90' },
    { title: 'Last 6 months', value: '180' },
    { title: 'Last 12 months', value: '360' },
  ];

  viewType: string = 'line';

  constructor(
    public constants: Constants,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private cardService: CardService,
    private rewardService: RewardService,
    private insightsService: InsightsService,
    private excelService: ExcelService,
    public dialog: MatDialog,
    private merchantService: MerchantService,
    private ga: GoogleAnalyticsService,
    private storageService: StorageService,
    private mixpanel: AnalyticsAbstract,
    private breakpointObserver: BreakpointObserver
  ) {
    this.viewType = this.storageService.get('view') ?? 'line';

    this.route.queryParams.subscribe((params) => {
      const tab = params['tab'];
      const customerId = params['customerId'];
      const p = params['p'];
      const days = params['days'];

      if (customerId) {
        this.customerId = customerId;
        this.removeCustomerIdQueryParam();
      }

      if (tab) {
        this.selectedTab = tab;
        this.selectedIndex = this.tabs.findIndex((el) => el === tab);
      }

      if (p) {
        this.stampPageIndex = parseFloat(p);
      }

      if (days) {
        this.days = days;
      }
    });
  }

  ngOnInit() {
    if (this.customerId?.slice(0, 4) === 'cus_') {
      this.dialog.open(PlanSuccessDialogComponent, {
        data: {
          customerId: this.customerId,
        },
        autoFocus: false,
        width: '400px',
        minHeight: '350px',
        disableClose: true,
        panelClass: 'custom-dialog',
        scrollStrategy: new NoopScrollStrategy(),
      });
    }

    this.loadData();
    this.getDashboardInsights();
  }

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result: BreakpointState) => {
        return result.matches;
      })
    );

  tapped(item: any): void {
    this.days = item.value;

    this.mixpanel.track(Constants.analytics_keys.toggleGraphDays, {
      Days: this.days,
    });

    // this.optionTapped.emit(this.days);
  }

  removeCustomerIdQueryParam(): void {
    const urlTree = this.router.createUrlTree([], {
      queryParams: { ...this.route.snapshot.queryParams },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });

    delete urlTree.queryParams['customerId'];

    this.router.navigateByUrl(urlTree, { replaceUrl: true });
  }

  get selected(): any {
    return this.dateOptions.find((el) => el.value === this.days).title;
  }

  loadData(): void {
    if (this.selectedTab === 'stamps') {
      this.getStampEvents();
    }
    if (this.selectedTab === 'redemptions') {
      this.getRedemptions();
    }
    if (this.selectedTab === 'cards') {
      this.getUserCards();
    }
    if (this.selectedTab === 'rewards') {
      this.getUserRewards();
    }
  }

  get loading(): boolean {
    return (
      this.insightsLoading ||
      this.eventsLoading ||
      this.redemptionsLoading ||
      this.exporting ||
      this.cardsLoading ||
      this.rewardsLoading
    );
  }

  getDashboardInsights(): void {
    this.insightsLoading = true;
    this.insightsService
      .getDashboardInsights()
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.insights = res;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.insightsLoading = false));
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');
  }

  getUserCards(): void {
    this.cardsLoading = true;

    this.merchantService
      .getCustomerCards(this.cardsPageSize, this.cardsPageIndex)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.cardsLength = res.totalDocs;
            this.cards = res.docs;

            this.cardsRange = this.constants.pageCounter(
              this.cardsPageIndex,
              this.cardsPageSize,
              this.cardsLength,
              this.cards?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.cardsLoading = false));
  }

  getUserRewards(): void {
    this.rewardsLoading = true;

    this.merchantService
      .getCustomerRewards(this.rewardsPageSize, this.rewardsPageIndex)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.rewardsLength = res.totalDocs;
            this.rewards = res.docs;

            this.rewardsRange = this.constants.pageCounter(
              this.rewardsPageIndex,
              this.rewardsPageSize,
              this.rewardsLength,
              this.rewards?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.rewardsLoading = false));
  }

  getStampEvents(): void {
    this.eventsLoading = true;
    this.cardService
      .getStampEvents(this.stampPageSize, this.stampPageIndex)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.stampLength = res.totalDocs;
            this.recentStamps = res.docs;

            this.stampsRange = this.constants.pageCounter(
              this.stampPageIndex,
              this.stampPageSize,
              this.stampLength,
              this.recentStamps?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.eventsLoading = false));
  }

  getRedemptions(): void {
    this.redemptionsLoading = true;
    this.rewardService
      .getRedemptions(this.redemptionPageSize, this.redemptionPageIndex)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.redemptionLength = res.totalDocs;
            this.redemptions = res.docs;

            this.redemptionsRange = this.constants.pageCounter(
              this.redemptionPageIndex,
              this.redemptionPageSize,
              this.redemptionLength,
              this.redemptions?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.redemptionsLoading = false));
  }

  addQueryParams(params: any) {
    this.route.queryParams.subscribe((queryParams) => {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: params,
        queryParamsHandling: 'merge',
        skipLocationChange: false,
      });
    });
  }

  tabChanged(index: any): void {
    let tab = this.tabs[index];

    this.addQueryParams({ tab: tab, p: 0, days: this.days });

    this.selectedTab = tab;

    if (index === 0) {
      this.ga.event('db_stamps');
    } else if (index === 1) {
      this.ga.event('db_redemptions');
    } else if (index === 2) {
      this.ga.event('db_cards');
    } else {
      this.ga.event('db_rewards');
    }

    this.mixpanel.track(Constants.analytics_keys.selectDashboardTab, {
      Tab: tab,
    });

    this.loadData();
  }

  cardPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.cardsPageIndex = event.offset;
      this.addQueryParams({ p: this.cardsPageIndex });
      this.getUserCards();
    }
  }

  rewardPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.rewardsPageIndex = event.offset;
      this.addQueryParams({ p: this.rewardsPageIndex });
      this.getUserRewards();
    }
  }

  stampPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.stampPageIndex = event.offset;
      this.addQueryParams({ p: this.stampPageIndex });
      this.getStampEvents();
    }
  }

  redemptionPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.redemptionPageIndex = event.offset;
      this.addQueryParams({ p: this.redemptionPageIndex });
      this.getRedemptions();
    }
  }

  handleScroll() {
    this.suppressPaging = true;

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPaging = false;
    }, 100);
  }

  viewTypeToggled(type: string): void {
    this.mixpanel.track(Constants.analytics_keys.toggleGraphType, {
      'Graph type': type,
    });

    this.storageService.set('view', type);
    this.viewType = type;
  }

  exportStamps(from: string, to: string): void {
    this.mixpanel.track(Constants.analytics_keys.dashboardExport, {
      From: from,
      To: to,
    });

    this.exporting = true;
    this.merchantService
      .export('stamps', from, to)
      .subscribe({
        next: (res: any) => {
          if (res.data && res.data.length === 0) {
            this.constants.snack('No data to export');
          } else {
            this.excelService.exportExcelFile(res.data.data, res.fileName);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.exporting = false));
  }

  daysSelected(days: any) {
    this.days = days;
    this.addQueryParams({ days: this.days });
  }

  onClick(event: any, message: any) {
    if (event === this.constants.strings.exportAllData) {
      this.mixpanel.track(Constants.analytics_keys.openDashboardExport);

      const dialogRef = this.dialog.open(DateRangeDialogComponent, {
        data: {},
        autoFocus: false,
        width: '350px',
        disableClose: true,
        panelClass: 'custom-dialog',
        scrollStrategy: new NoopScrollStrategy(),
      });
      dialogRef.afterClosed().subscribe((dates: any) => {
        if (dates) {
          this.exportStamps(dates.from, dates.to);
        }
      });
    }
    if (event === this.constants.strings.lapsedCustomerReport) {
      this.lapsedCustomerReport();
    }
  }

  lapsedCustomerReport(): void {
    this.mixpanel.track(Constants.analytics_keys.lapsedCustomerReport);

    this.exporting = true;
    this.merchantService
      .export('lapsedCustomers')
      .subscribe({
        next: (res: any) => {
          if (res.data && res.data.length === 0) {
            this.constants.snack('No data to export');
          } else {
            this.excelService.exportExcelFile(res.data.data, res.fileName);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.exporting = false));
  }
}
