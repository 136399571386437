import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.css'],
})
export class SingleSelectComponent {
  @Input() subtitle?: string;
  @Input() infoText?: string;
  @Input() label?: string;
  @Input() isTimeInput: boolean = false;
  @Input() inBrackets?: string;
  @Input() formCtrl!: FormControl;
  @Input() options: any[] = [];
  @Input() value: any;
  @Input() property?: string;
  @Input() initialIndex: number = 0;
  @Input() showIcon = false;
  @Input() filled = false;
  @Input() webIcon = false;
  @Input() border = 'none';
  @Input() showSearch = false;

  @Output() subtitleTapped: EventEmitter<any> = new EventEmitter();
  @Output() selected: EventEmitter<any> = new EventEmitter();

  @ViewChild('matSelect') matSelect?: ElementRef;
  searchFormControl: FormControl = new FormControl();
  searchActive = false;
  filteredOptions: any[] = [];

  constructor(
    public datePipe: DatePipe,
    private overlayContainer: OverlayContainer
  ) {}

  selection(event: any) {
    this.selected.emit(event.value);

    setTimeout(() => {
      this.searchActive = false;
      this.searchFormControl.setValue('');
    }, 200);
  }

  ngOnInit() {
    this.matSelect?.nativeElement?.openedChange.subscribe((opened: any) => {
      if (!opened) {
        this.overlayContainer
          .getContainerElement()
          .classList.remove('select-overlay');
      }
    });
    this.compareCategoryObjects = this.compareCategoryObjects.bind(this);

    this.filteredOptions = this.options;
  }

  compareCategoryObjects(object1: any, object2: any) {
    if (this.value?.name) {
      return object1 && object2 && object1.name == object2.name;
    } else {
      if (this.property) {
        return object1 && object2 && object1.value == object2.value;
      } else {
        return object1 && object2 && object1 == object2;
      }
    }
  }

  formatTime(time: number): string {
    const hours = Math.floor(time);
    const minutes = Math.floor((time - hours) * 60);
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  }

  get isMandatory() {
    if (this.formCtrl) {
      const validator = this.formCtrl.validator
        ? this.formCtrl.validator({} as AbstractControl)
        : null;
      if (validator && validator['required']) {
        return true;
      }
    }
    return false;
  }

  onTypeSearch(): void {
    let searchTerm = this.searchFormControl.value?.toLowerCase();
    this.searchActive = searchTerm !== '';

    if (this.searchActive) {
      this.filteredOptions = this.options.filter((el) => {
        if (this.property) {
          return el[this.property]?.toLowerCase().includes(searchTerm);
        } else {
          return el?.toLowerCase().includes(searchTerm);
        }
      });
    }
  }
}
