import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { Constants } from 'src/app/app.constants';
import { InsightsService } from '../../../services/index';
import { Observable } from 'rxjs';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';

@Component({
  selector: 'chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css'],
})
export class ChartComponent {
  labels: string[] = [];
  loading = false;
  @Input() type = 'stamp';
  @Input() days = '30';
  @Input() chartType = 'line';
  @Input() customer: string | undefined;
  @Output() optionTapped = new EventEmitter<string>();

  public barChartLegend = false;
  public barChartPlugins = [];

  barChartData: ChartData | undefined;
  title: string = '';
  menuOptions: any[] = [
    { title: 'Last 7 days', value: '7' },
    { title: 'Last 30 days', value: '30' },
    { title: 'Last 3 months', value: '90' },
    { title: 'Last 6 months', value: '180' },
    { title: 'Last 12 months', value: '360' },
  ];

  constructor(
    private insightService: InsightsService,
    private constants: Constants,
    private mixpanel: AnalyticsAbstract
  ) {}

  tapped(item: any): void {
    this.days = item.value;

    this.mixpanel.track(Constants.analytics_keys.toggleGraphDays, {
      Days: this.days,
    });

    this.optionTapped.emit(this.days);
  }

  public barChartOptions: ChartConfiguration['options'] = {};

  values(valueType: string, events: any[]): number[] {
    const val: number[] = [];

    if (events?.length) {
      for (const label of this.labels) {
        const [day, month] = label.split('/').map(Number);
        const year = new Date().getFullYear();
        // Assuming `events` data includes day, month, and year for comparison
        const obj = events.find(
          (el: any) => el.day === day && el.month === month && el.year === year
        );

        val.push(obj ? parseFloat(obj[valueType].toFixed(0)) : 0);
      }

      return val;
    } else {
      return [];
    }
  }

  onChanged(): void {
    this.mixpanel.track(Constants.analytics_keys.toggleGraphDays, {
      Days: this.days,
    });

    this.optionTapped.emit(this.days);
  }

  calculateWeekNumber(date: Date): number {
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const daysBetween =
      Math.floor(
        (date.getTime() - startOfYear.getTime()) / (24 * 60 * 60 * 1000)
      ) + 1; // Add 1 to account for the current day
    const weekNumber = Math.ceil((daysBetween + startOfYear.getDay()) / 7);
    return weekNumber;
  }

  parseSundayLabel(label: string): string {
    const [day, month] = label.split('/');
    return day + '/' + month;
  }

  randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  setTitle(): void {
    this.title = `Total ${
      this.type[0].toUpperCase() + this.type.slice(1)
    }s per day`;

    this.barChartOptions = {
      responsive: true,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          ticks: {
            stepSize: 1,
            callback: function (value: number | string) {
              const num = typeof value === 'number' ? value : parseFloat(value);
              if (num === 0) {
                return '-';
              } else if (!isNaN(num) && num % 1 === 0) {
                return num;
              } else {
                return '';
              }
            },
          },
        },
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
      plugins: {
        title: {
          display: true,
          text: this.title,
          font: {
            size: 14,
            weight: '500',
          },
          padding: {
            top: 10,
            bottom: 10,
          },
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          callbacks: {
            title: () => '',
            label: (context: { dataset: any; parsed: any }) => {
              let value = context.parsed.y;
              const labelIndex = context.parsed.x;
              const hourLabel = this.labels[labelIndex];
              return `${hourLabel} - ${value.toFixed(0)}`;
            },
          },
        },
        legend: {
          labels: {
            usePointStyle: true,
            boxWidth: 20,
          },
        },
      },
    };
  }

  ngOnInit(): void {
    this.setTitle();
  }

  ngOnChanges(): void {
    this.getLabels();
    this.getBarChartData();
  }

  getLabels(): void {
    const daysToShow = +this.days; // For example, show the last 30 days
    const format = 'dd/MM';
    const locale = 'en-GB';
    const currentDate = new Date();

    this.labels = Array.from({ length: daysToShow }, (_, i) => {
      const dt = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - i
      );
      return formatDate(dt, format, locale);
    }).reverse(); // Reverse to get the dates in chronological order
  }

  getBarChartData(): void {
    this.loading = true;

    let observable$;
    if (this.type === 'stamp') {
      observable$ = this.insightService.getWeeklyStamps(
        +this.days,
        this.customer
      );
    } else if (this.type === 'card') {
      observable$ = this.insightService.getWeeklyCards(+this.days);
    } else if (this.type === 'reward') {
      observable$ = this.insightService.getWeeklyRewards(
        +this.days,
        this.customer
      );
    } else {
      observable$ = this.insightService.getWeeklyRedemptions(
        +this.days,
        this.customer
      );
    }

    this.processResponse(observable$);
  }

  processResponse(observable$: Observable<any>): void {
    observable$
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.createChartData(res);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  createChartData(data: any): void {
    const color = '#f1a638';

    this.barChartData = {
      labels: this.labels,
      datasets: [
        {
          data: this.values('value', data),
          label: this.labelType,
          pointBackgroundColor: 'transparent',
          pointBorderWidth: 0,
          pointBorderColor: color,
          hoverBorderColor: color,
          borderColor: color,
          backgroundColor: this.convertHexToRGBA(color),
          hoverBackgroundColor: this.convertHexToRGBA(color),
          borderRadius: 10,
          borderWidth: 2,
          fill: 'origin',
          tension: 0.4,
        },
      ],
    };
  }

  convertHexToRGBA(hex: string): string {
    // Check if hex color is valid and add '#' if missing
    if (hex.startsWith('#') === false) {
      hex = '#' + hex;
    }

    // Extract the hex color components
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    // Return the RGBA color with 50% transparency
    return `rgba(${r}, ${g}, ${b}, 0.5)`;
  }

  get labelType(): string {
    if (this.type === 'card') {
      return 'Cards';
    }
    if (this.type === 'stamp') {
      return 'Stamps';
    }
    if (this.type === 'reward') {
      return 'Rewards';
    }
    return 'Redemptions';
  }

  // public chartClicked({
  //   event,
  //   active,
  // }: {
  //   event?: ChartEvent;
  //   active?: {}[];
  // }): void {}

  // public chartHovered({
  //   event,
  //   active,
  // }: {
  //   event?: ChartEvent;
  //   active?: {}[];
  // }): void {}
}
