import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { TwoOptionAlertComponent } from 'src/app/shared/components/two-option-alert/two-option-alert.component';

import { RewardService } from '../../../services/index';
import { Reward } from '../../../models/index';

@Component({
  selector: 'give-reward-dialog',
  templateUrl: './give-reward-dialog.component.html',
  styleUrls: ['./give-reward-dialog.component.css'],
})
export class GiveRewardDialogComponent implements OnInit {
  form!: FormGroup;
  rewardFormControl!: FormControl;
  numberFormControl!: FormControl;

  rewards: Reward[] = [];
  loading = false;
  error = false;

  quantityOptions = Array.from({ length: 10 }, (_, index) => index + 1)
    .filter((el: number) => {
      return !this.rewards.some((ex: any) => ex?.stamp?.number === el);
    })
    .map((el: number) => ({ number: el }));

  constructor(
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<GiveRewardDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rewardService: RewardService,
    private constants: Constants
  ) {}

  ngOnInit(): void {
    this.rewardFormControl = new FormControl({ value: '', disabled: false }, [
      Validators.required,
    ]);
    this.numberFormControl = new FormControl({ value: '', disabled: false }, [
      Validators.required,
    ]);

    this.form = this.fb.group({
      reward: this.rewardFormControl,
      number: this.numberFormControl,
    });

    this.getRewards();
  }

  getRewards(): void {
    this.loading = true;
    this.rewardService
      .getRewards(1000, 0)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.rewards = res.docs;
            this.rewards?.sort((a, b) => a.title!.localeCompare(b.title!));
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  primaryTapped() {
    if (this.form?.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const quantity = this.numberFormControl.value.number;
    const reward = this.rewardFormControl.value;

    const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: 'Confirm',
        body: `Do you want to give ${this.data.customer} ${quantity} <b>${
          reward.title
        }</b> ${quantity === 1 ? 'reward' : 'rewards'}? ${
          quantity === 1 ? 'This' : 'These'
        } will show in their rewards wallet and they will not be notified`,
        buttonOne: 'Cancel',
        buttonTwo: 'Yes',
      },
      autoFocus: false,
      width: '300px',
      disableClose: true,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((option) => {
      if (option == 1) {
        this.matDialogRef.close({
          reward: reward,
          quantity: quantity,
        });
      }
    });
  }

  hasRewards(): void {
    if (!this.rewards.length) {
      this.constants.snack('Please add a reward first');
    }
  }
}
