export const environment = {
  production: false,
  apiUrl: 'https://dytd71qnbg9s8.cloudfront.net/v1/',
  socketUrl: 'https://dytd71qnbg9s8.cloudfront.net',
  iosAppStore: 'https://apps.apple.com/ky/app/id6468891390',
  androidStore:
    'https://play.google.com/store/apps/details?id=com.example.remy_mobile',
  imageUrl: '',
  stripeCustomerPortal:
    'https://billing.stripe.com/p/login/test_dR63e86Xq4fk2zu6op',
  googleAnalyticsID: 'G-XS0K54TB58',
  hCaptchaSiteKey: 'ed7e21e4-59f7-4ca0-991a-6cc48082e52e',
  mixpanel: '39820e0830ad1b4eb6a221cefc381b43',
};
