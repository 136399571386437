<page-header
  [title]="(editMode ? 'Edit ' : 'New ') + constants.strings.stampCard"
  [subtitle]="subtitle"
></page-header>

<div style="display: flex; justify-content: flex-end; margin-bottom: 10px">
  <div style="display: flex">
    <app-primary-button
      [title]="constants.strings.cancel"
      border=" 1px solid var(--app-container)"
      bgColor="transparent"
      color="white"
      (tapped)="back()"
    ></app-primary-button>

    <div style="margin-left: 10px">
      <app-primary-button
        title="Save"
        [disabled]="loading || !canCreateCard"
        border=" 1px solid var(--app-container)"
        [tooltip]="tooltip"
        (tapped)="save()"
      ></app-primary-button>
    </div>
  </div>
</div>

<div *ngIf="isHandset$ | async" style="margin-bottom: 20px">
  <card-preview-container
    [textColour]="textColorFormControl.value"
    [cardColour]="cardColorFormControl.value"
    [stampColour]="stampFillColorFormControl.value"
    [stampFillEmpty]="stampFillEmptyFormControl.value"
    [stampOutlineColour]="stampColorFormControl.value"
    [stamps]="stampArray"
    [stampIcon]="stampIcon"
    [rewardIcon]="rewardIcon"
    [rewardIcons]="rewardIcons"
    [rewards]="rewards"
    [title]="titleFormControl.value"
    [description]="descriptionFormControl.value"
    [iconOpacity]="opacitySliderValue / 10"
    [iconColor]="iconColorFormControl.value"
    [showLogo]="showLogo"
    [showStamps]="showStamps"
    [shape]="shape"
    [cardGradient]="cardGradient"
    [stampImage]="stampImage?.content ?? card.stampImage?.url"
    [rewardImage]="rewardImage?.content ?? card.rewardImage?.url"
    [merchantLogo]="merchantLogo"
  >
    ></card-preview-container
  >
</div>

<form [formGroup]="form" *ngIf="!loading">
  <div
    class="main-container"
    style="display: flex; justify-content: stretch; margin-bottom: 70px"
  >
    <div
      class="detail-container"
      id="left"
      style="width: 100%; margin-right: 20px"
    >
      <mat-tab-group
        disableRipple="true"
        [animationDuration]="0"
        [(selectedIndex)]="selectedIndex"
        (selectedTabChange)="tabChanged($event.index)"
        class="remove-border-bottom"
      >
        <mat-tab label="Overview">
          <div class="col-lg-6">
            <div class="form-field" style="margin-top: 20px">
              <div class="form-field-label">
                Title<span style="color: red">*</span>
              </div>
              <input
                class="form-input"
                placeholder="e.g. Free coffee"
                autocomplete="off"
                [formControl]="titleFormControl"
                maxlength="38"
                type="text"
                [ngStyle]="{
                  border:
                    titleFormControl.invalid && submitted
                      ? '1px solid red'
                      : '1px solid var(--app-light-grey)'
                }"
              />
            </div>

            <div class="form-field" style="margin-top: 20px">
              <div class="form-field-label">
                Description<span style="color: red">*</span>
              </div>
              <textarea
                class="form-input"
                autocomplete="off"
                [formControl]="descriptionFormControl"
                placeholder="e.g. Buy 9 coffees, get your 10th free!"
                type="text"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                [maxlength]="78"
                [rows]="2"
                [ngStyle]="{
                  border:
                    descriptionFormControl.invalid && submitted
                      ? '1px solid red'
                      : '1px solid var(--app-light-grey)'
                }"
              ></textarea>
            </div>

            <div
              class="form-field"
              style="margin-top: 20px"
              *ngIf="showCustomBrandingOption"
            >
              <single-select
                label="Display as"
                [infoText]="displayAsTooltip"
                [formCtrl]="fromFormControl"
                [options]="fromOptions"
                property="name"
                [value]="fromFormControl.value"
                (selected)="displayAsSelected($event)"
              ></single-select>
            </div>

            <div
              class="form-field"
              style="margin-top: 20px"
              *ngIf="(locations ?? []).length > 1"
            >
              <multiple-select
                label="Locations"
                [formCtrl]="locationFormControl"
                [options]="locations"
                [disabled]="disabledLocations"
                [showAll]="false"
                value="name"
                (click)="hasLocations()"
              >
              </multiple-select>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Theme">
          <div class="form-field" style="margin-top: 20px">
            <div class="form-field-label">Colours</div>

            <div style="display: flex; margin-top: 10px">
              <div class="colour-container" style="margin-right: 20px">
                <div
                  class="colour-avatar"
                  [ngStyle]="{ 'background-color': iconColorFormControl.value }"
                >
                  <ngx-colors
                    ngx-colors-trigger
                    style="display: inline-block; margin: 5px; opacity: 0"
                    [formControl]="iconColorFormControl"
                    [colorPickerControls]="'no-alpha'"
                    format="hex"
                  ></ngx-colors>
                </div>
                <div class="colour-label">Icons</div>
              </div>

              <div class="colour-container" style="margin-right: 20px">
                <div
                  [mat-menu-trigger-for]="colorMenu"
                  class="colour-avatar"
                  [ngStyle]="
                    cardColorFormControl.value
                      ? {
                          background: cardColorFormControl.value,
                          cursor: 'pointer'
                        }
                      : {
                          background:
                            'linear-gradient(' +
                            cardGradient?.angle +
                            'deg, ' +
                            cardGradient?.from +
                            ' ' +
                            0 +
                            '%, ' +
                            cardGradient?.from +
                            ' ' +
                            cardGradient?.value +
                            '%, ' +
                            cardGradient?.to +
                            ' ' +
                            100 +
                            '%)',
                          cursor: 'pointer'
                        }
                  "
                ></div>
                <div class="colour-label">Card</div>
              </div>

              <div class="colour-container" style="margin-right: 20px">
                <div
                  class="colour-avatar"
                  [ngStyle]="{ 'background-color': textColorFormControl.value }"
                >
                  <ngx-colors
                    ngx-colors-trigger
                    style="display: inline-block; margin: 5px; opacity: 0"
                    [formControl]="textColorFormControl"
                    [colorPickerControls]="'no-alpha'"
                    format="hex"
                  ></ngx-colors>
                </div>
                <div class="colour-label">Text</div>
              </div>
            </div>

            <div style="display: flex; margin-top: 10px">
              <div class="colour-container" style="margin-right: 20px">
                <div
                  [mat-menu-trigger-for]="stampOutlineMenu"
                  class="colour-avatar"
                  [ngStyle]="{
                    'background-color': stampColorFormControl.value
                      ? stampColorFormControl.value
                      : 'transparent',
                    cursor: 'pointer'
                  }"
                ></div>
                <div class="colour-label">Stamp outline</div>
              </div>

              <div class="colour-container" style="margin-right: 20px">
                <div
                  [mat-menu-trigger-for]="stampFillMenu"
                  class="colour-avatar"
                  [ngStyle]="{
                    'background-color': stampFillColorFormControl.value
                      ? stampFillColorFormControl.value
                      : 'transparent',
                    cursor: 'pointer'
                  }"
                ></div>
                <div class="colour-label">Stamp fill</div>
              </div>

              <div class="colour-container" style="margin-right: 20px">
                <div
                  [mat-menu-trigger-for]="stampFillEmptyMenu"
                  class="colour-avatar"
                  [ngStyle]="{
                    'background-color': stampFillEmptyFormControl.value
                      ? stampFillEmptyFormControl.value
                      : 'transparent',
                    cursor: 'pointer'
                  }"
                ></div>
                <div class="colour-label">Stamp fill (pre stamp)</div>
              </div>
            </div>
          </div>

          <div class="form-field" style="margin-top: 20px">
            <div class="form-field-label">Stamp shape</div>

            <div style="display: flex; margin-top: 10px">
              <div
                class="shape-container"
                style="margin-right: 20px"
                (click)="shape = shapesEnum.square"
              >
                <div
                  class="shape-avatar-outer"
                  [ngStyle]="{
                    'border-radius': borderRadius(shapesEnum.square),
                    'border-color':
                      shape === shapesEnum.square
                        ? selectedShapeBorderColor
                        : ''
                  }"
                >
                  <div
                    class="shape-avatar"
                    [ngStyle]="{
                      'border-radius': borderRadius(shapesEnum.square)
                    }"
                  ></div>
                </div>
              </div>
              <div
                class="shape-container"
                style="margin-right: 20px"
                (click)="shape = shapesEnum.circle"
              >
                <div
                  class="shape-avatar-outer"
                  [ngStyle]="{
                    'border-radius': borderRadius(shapesEnum.circle),
                    'border-color':
                      shape === shapesEnum.circle
                        ? selectedShapeBorderColor
                        : ''
                  }"
                >
                  <div
                    class="shape-avatar"
                    [ngStyle]="{
                      'border-radius': borderRadius(shapesEnum.circle)
                    }"
                  ></div>
                </div>
              </div>
              <div
                class="shape-container"
                style="margin-right: 20px"
                (click)="shape = shapesEnum.rounded"
              >
                <div
                  class="shape-avatar-outer"
                  [ngStyle]="{
                    'border-radius': borderRadius(shapesEnum.rounded),
                    'border-color':
                      shape === shapesEnum.rounded
                        ? selectedShapeBorderColor
                        : ''
                  }"
                >
                  <div
                    class="shape-avatar"
                    [ngStyle]="{
                      'border-radius': borderRadius(shapesEnum.rounded)
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-field" style="margin-top: 20px">
            <div class="form-field-label">Icons</div>
            <div style="display: flex; margin-top: 10px">
              <div class="colour-container" style="margin-right: 20px">
                <div class="icon-avatar" (click)="openIconPickerDialog(false)">
                  <mat-icon
                    *ngIf="!stampImage?.content && !card.stampImage?.url"
                    class="icon"
                    [fontSet]="
                      stampIcon?.filled
                        ? 'material-icons'
                        : 'material-icons-outlined'
                    "
                    >{{ stampIcon?.web }}</mat-icon
                  >
                  <img
                    *ngIf="stampImage?.content || card.stampImage?.url"
                    style="width: 25px; height: 25px"
                    [src]="stampImage?.content ?? card.stampImage?.url"
                  />
                </div>
                <div class="colour-label">Stamp</div>
              </div>
              <div class="colour-container" style="margin-right: 20px">
                <div class="icon-avatar" (click)="openIconPickerDialog(true)">
                  <mat-icon
                    *ngIf="!rewardImage?.content && !card.rewardImage?.url"
                    class="icon"
                    [fontSet]="
                      rewardIcon?.filled
                        ? 'material-icons'
                        : 'material-icons-outlined'
                    "
                    >{{ rewardIcon?.web }}</mat-icon
                  >
                  <img
                    *ngIf="rewardImage?.content || card.rewardImage?.url"
                    style="width: 25px; height: 25px"
                    [src]="rewardImage?.content ?? card.rewardImage?.url"
                  />
                </div>
                <div class="colour-label">Reward</div>
              </div>
            </div>
          </div>

          <!-- <div class="form-field" style="margin-top: 20px">
            <div class="form-field-label">Background Image</div>
            <div style="display: flex; margin-top: 10px">
              <file-upload
                (fileSelected)="selectedBackgroundImage = $event"
                [readerResult]="initialBackgroundImage ?? ''"
                [rounded]="true"
                [maintainAspectRatio]="false"
              ></file-upload>
            </div>
            <div
              class="col-lg-6"
              *ngIf="initialBackgroundImage || selectedBackgroundImage?.length"
            >
              <div class="form-field-label">Background Image Opacity</div>
              <div class="custom-slider">
                <ngx-slider
                  [(value)]="bgImageOpacityValue"
                  [options]="opacitySliderOptions"
                ></ngx-slider>
              </div>
            </div>
          </div> -->
        </mat-tab>

        <mat-tab label="Stamping">
          <div style="overflow: hidden">
            <div class="row">
              <div class="col-lg-4">
                <div class="form-field" style="margin-top: 20px">
                  <single-select
                    label="Total stamps"
                    [infoText]="requiredStampsTooltip"
                    [formCtrl]="totalStampsFormControl"
                    [options]="totalStampsOptions"
                    [value]="totalStampsFormControl.value"
                    (selected)="updateStampOptions($event)"
                  >
                  </single-select>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-field" style="margin-top: 20px">
                  <single-select
                    label="Join stamps"
                    infoText="The initial number of free stamps your customers should receive upon taking your Stamp Card. Choose 0 for no joining stamps"
                    [formCtrl]="joinStampsFormControl"
                    [options]="joinStampsOptions"
                    [value]="joinStampsFormControl.value"
                  >
                  </single-select>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-field" style="margin-top: 20px">
                  <single-select
                    label="Stamps per scan"
                    infoText="The number of stamps to be awarded by a single scan, you can set an automation to revert back to the original number"
                    [formCtrl]="stampsPerScanStampsFormControl"
                    [options]="stampsPerScanOptions"
                    [value]="stampsPerScanStampsFormControl.value"
                    (selected)="
                      openRevertDialog(
                        card.stampPromo?.active
                          ? card.stampPromo?.originalStamps
                          : card.stampsPerScan
                      )
                    "
                  >
                  </single-select>
                  <p
                    class="revert-string"
                    *ngIf="stampPromoString"
                    (click)="
                      openRevertDialog(card.stampPromo?.originalStamps ?? 1)
                    "
                  >
                    {{ stampPromoString }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-8">
              <hr />
              <div
                class="form-field"
                style="
                  margin-top: 20px;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <div class="form-field-label">Daily stamp restriction</div>
                <mat-slide-toggle
                  [disabled]="false"
                  [checked]="stampsPerDay"
                  (change)="stampsPerDay = !stampsPerDay"
                >
                </mat-slide-toggle>
              </div>

              <div
                class="form-field"
                style="margin-left: 10px; margin-right: 10px"
                *ngIf="stampsPerDay"
              >
                <div style="width: 100%">
                  <div class="form-field-label">
                    Max stamps per day<span style="color: red">*</span
                    ><span
                      ><info-icon [text]="restrictionTooltip"></info-icon>
                    </span>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      flex-direction: row;
                      align-items: center;
                    "
                  >
                    <input
                      class="form-input"
                      autocomplete="off"
                      placeholder="e.g. 1"
                      [formControl]="stampsPerDayStampsFormControl"
                      type="number"
                      [maxlength]="3"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    />
                    <div
                      class="form-field-label-subtitle"
                      style="
                        width: 60px;
                        padding-left: 10px;
                        margin-bottom: 0px;
                      "
                    >
                      stamps
                    </div>
                  </div>
                </div>
              </div>

              <!-- <mat-radio-group
                [value]="selectedDailyOption"
                *ngIf="stampsPerDay"
                style="margin-left: 10px"
                aria-label="Select an option"
                (change)="selectedDailyOption = $event.value"
              >
                <div
                  class="form-field-label"
                  style="text-align: left; margin-left: 10px"
                >
                  Applies to:
                </div>
                <div
                  *ngFor="let option of options"
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-left: 20px;
                    margin-bottom: 10px;
                  "
                >
                  <span>{{ option.label }}</span>
                  <mat-radio-button [value]="option.value"></mat-radio-button>
                </div>
              </mat-radio-group> -->
            </div>

            <div class="col-lg-8">
              <hr *ngIf="stampsPerDay" />
              <div
                class="form-field"
                style="
                  margin-top: 20px;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <div class="form-field-label">Interval restriction</div>
                <mat-slide-toggle
                  [disabled]="false"
                  [checked]="interval"
                  (change)="interval = !interval"
                >
                </mat-slide-toggle>
              </div>

              <div
                class="form-field"
                style="margin-left: 10px; margin-right: 10px"
                *ngIf="interval"
              >
                <div class="form-field-label">
                  Time between stamps<span style="color: red">*</span
                  ><span
                    ><info-icon [text]="intervalRestrictionTooltip"></info-icon>
                  </span>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 10px;
                      "
                    >
                      <input
                        class="form-input"
                        autocomplete="off"
                        placeholder="e.g. 30"
                        [formControl]="intervalHoursFormControl"
                        type="number"
                        [maxlength]="2"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      />
                      <div
                        class="form-field-label-subtitle"
                        style="
                          width: 60px;
                          padding-left: 10px;
                          margin-bottom: 0px;
                        "
                      >
                        hours
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;
                        align-items: center;
                      "
                    >
                      <input
                        class="form-input"
                        autocomplete="off"
                        placeholder="e.g. 30"
                        [formControl]="intervalMinutesFormControl"
                        type="number"
                        [maxlength]="2"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      />
                      <div
                        class="form-field-label-subtitle"
                        style="
                          width: 60px;
                          padding-left: 10px;
                          margin-bottom: 0px;
                        "
                      >
                        minutes
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Rewards">
          <div style="overflow: hidden">
            <div class="col-lg-6">
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-top: 20px;
                  justify-content: space-between;
                "
              >
                <div style="display: flex; align-items: center">
                  <div
                    class="form-field-label"
                    style="margin-bottom: 0px; margin-right: 5px"
                  >
                    Add a Reward
                  </div>

                  <mat-icon
                    class="status-icon"
                    style="color: var(--app-timber); cursor: pointer"
                    (click)="openRewardDialog()"
                    >add_circle</mat-icon
                  >
                </div>
                <div
                  class="form-field-label"
                  style="margin-bottom: 0px"
                  *ngIf="rewards?.length"
                >
                  Icon
                </div>
              </div>

              <div
                *ngFor="let item of rewards; let i = index"
                style="margin-top: 10px"
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      margin-right: 20px;
                    "
                    class="social-container"
                  >
                    <div style="display: flex; align-items: center">
                      <div
                        class="form-field-label"
                        style="
                          margin-bottom: 0px !important;
                          color: black;
                          font-size: 16px;
                          margin-right: 5px;
                        "
                      >
                        {{ item?.stamp?.number ?? item?.stamp }}:
                      </div>
                      <div
                        class="form-field-label"
                        style="
                          margin-bottom: 0px !important;
                          color: black;
                          font-size: 16px;
                        "
                      >
                        {{ item?.reward?.title }}
                      </div>
                    </div>

                    <mat-icon
                      style="color: red; cursor: pointer"
                      (click)="removeReward(item)"
                      >cancel
                    </mat-icon>
                  </div>

                  <div
                    style="cursor: pointer"
                    (click)="openIconPickerDialog(true, true, item)"
                  >
                    <mat-icon
                      *ngIf="!customRewardImage(item)"
                      class="icon"
                      [fontSet]="
                        customRewardIcon(item)?.filled
                          ? 'material-icons'
                          : 'material-icons-outlined'
                      "
                      >{{ customRewardIcon(item)?.web }}</mat-icon
                    >
                    <img
                      *ngIf="customRewardImage(item)"
                      style="width: 25px; height: 25px"
                      [src]="customRewardImage(item)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div>
      <card-preview-container
        *ngIf="!(isHandset$ | async)"
        [textColour]="textColorFormControl.value"
        [cardColour]="cardColorFormControl.value"
        [stampColour]="stampFillColorFormControl.value"
        [stampFillEmpty]="stampFillEmptyFormControl.value"
        [stampOutlineColour]="stampColorFormControl.value"
        [stamps]="stampArray"
        [stampIcon]="stampIcon"
        [rewardIcon]="rewardIcon"
        [rewardIcons]="rewardIcons"
        [rewards]="rewards"
        [title]="titleFormControl.value"
        [description]="descriptionFormControl.value"
        [iconOpacity]="opacitySliderValue / 10"
        [iconColor]="iconColorFormControl.value"
        [showLogo]="showLogo"
        [showStamps]="showStamps"
        [shape]="shape"
        [cardGradient]="cardGradient"
        [stampImage]="stampImage?.content ?? card.stampImage?.url"
        [rewardImage]="rewardImage?.content ?? card.rewardImage?.url"
        [merchantLogo]="merchantLogo"
      ></card-preview-container>
    </div>
  </div>
</form>

<loader [loading]="loading"></loader>

<mat-menu #colorMenu="matMenu" class="custom-menu">
  <button class="menu-list-item" style="border-radius: 5px" mat-menu-item>
    <div
      style="
        width: 15px;
        height: 15px;
        border-radius: 50%;
        font-size: 16px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        margin-right: 10px;
      "
      [ngStyle]="{ 'background-color': 'var(--app-timber)' }"
    >
      <ngx-colors
        ngx-colors-trigger
        style="display: inline-block; opacity: 0"
        [formControl]="cardColorFormControl"
        (change)="logEvent($event, 'change')"
        (close)="logEvent($event, 'close')"
        [colorPickerControls]="'no-alpha'"
        format="hex"
      ></ngx-colors>
    </div>
    Solid
  </button>

  <button
    class="menu-list-item"
    style="border-radius: 5px"
    mat-menu-item
    (click)="openGradientDialog()"
  >
    <div
      style="
        width: 15px;
        height: 15px;
        border-radius: 50%;
        font-size: 16px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        margin-right: 10px;
      "
      [ngStyle]="{
        'background-image':
          'linear-gradient(45deg, var(--app-timber), var(--app-beachy))'
      }"
    ></div>
    Gradient
  </button>
</mat-menu>

<mat-menu #stampOutlineMenu="matMenu" class="custom-menu">
  <button class="menu-list-item" style="border-radius: 5px" mat-menu-item>
    <div
      style="
        width: 15px;
        height: 15px;
        border-radius: 50%;
        font-size: 16px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        margin-right: 10px;
      "
      [ngStyle]="{ 'background-color': 'var(--app-timber)' }"
    >
      <ngx-colors
        ngx-colors-trigger
        style="display: inline-block; opacity: 0"
        [formControl]="stampColorFormControl"
        [colorPickerControls]="'no-alpha'"
        format="hex"
      ></ngx-colors>
    </div>
    Solid
  </button>

  <button
    class="menu-list-item"
    style="border-radius: 5px"
    mat-menu-item
    (click)="stampColorFormControl.setValue(null)"
  >
    No Colour
  </button>
</mat-menu>

<mat-menu #stampFillMenu="matMenu" class="custom-menu">
  <button class="menu-list-item" style="border-radius: 5px" mat-menu-item>
    <div
      style="
        width: 15px;
        height: 15px;
        border-radius: 50%;
        font-size: 16px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        margin-right: 10px;
      "
      [ngStyle]="{ 'background-color': 'var(--app-timber)' }"
    >
      <ngx-colors
        ngx-colors-trigger
        style="display: inline-block; opacity: 0"
        [formControl]="stampFillColorFormControl"
        [colorPickerControls]="'no-alpha'"
        format="hex"
      ></ngx-colors>
    </div>
    Select Colour
  </button>

  <button
    class="menu-list-item"
    style="border-radius: 5px"
    mat-menu-item
    (click)="stampFillColorFormControl.setValue(null)"
  >
    No Colour
  </button>
</mat-menu>

<mat-menu #stampFillEmptyMenu="matMenu" class="custom-menu">
  <button class="menu-list-item" style="border-radius: 5px" mat-menu-item>
    <div
      style="
        width: 15px;
        height: 15px;
        border-radius: 50%;
        font-size: 16px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        margin-right: 10px;
      "
      [ngStyle]="{ 'background-color': 'var(--app-timber)' }"
    >
      <ngx-colors
        ngx-colors-trigger
        style="display: inline-block; opacity: 0"
        [formControl]="stampFillEmptyFormControl"
        [colorPickerControls]="'no-alpha'"
        format="hex"
      ></ngx-colors>
    </div>
    Select Colour
  </button>

  <button
    class="menu-list-item"
    style="border-radius: 5px"
    mat-menu-item
    (click)="stampFillEmptyFormControl.setValue(null)"
  >
    No Colour
  </button>
</mat-menu>
